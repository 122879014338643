import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../Firebase';
import AppDownloadButton from './AppDownloadButton';
import * as Sentry from '@sentry/react';
import { auth } from '../Firebase';

const HereInHistory = () => {
    const { placeId } = useParams();
    const [placeData, setPlaceData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const trackEvent = async (eventName, eventData) => {
        const data = {
            user: auth.currentUser?.email || auth.currentUser?.uid,
            event_name: eventName,
            manufacturer: 'Web',
            ...eventData,
        };

        fetch('https://timetravel-dd209e3dce1f.herokuapp.com/log', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then((data) => {
            console.log('Success:', data);
        })
        .catch((error) => {
            Sentry.captureException(error);
            console.error('Error:', error);
        });
    };

    useEffect(() => {
        const fetchPlaceData = async () => {
            try {
                const placeRef = doc(db, 'placesinhistory', placeId);
                const placeDoc = await getDoc(placeRef);
                
                if (placeDoc.exists()) {
                    setPlaceData(placeDoc.data());
                    trackEvent('timeportal-here-in-history', {
                        place_id: placeId
                    });
                } else {
                    setError('Place not found');
                }
            } catch (err) {
                Sentry.captureException(err);
                setError('Error loading place data');
                console.error('Error fetching place data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchPlaceData();
    }, [placeId]);

    if (loading) {
        return (
            <div className="min-h-screen bg-black flex items-center justify-center">
                <p className="text-white text-xl">Loading...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen bg-black flex items-center justify-center">
                <p className="text-white text-xl">{error}</p>
            </div>
        );
    }

    return (
        <div className="fixed inset-0 bg-black overflow-hidden">
            {placeData?.video && (
                <div className="absolute inset-0 z-0">
                    <video
                        src={placeData.video}
                        className="h-full w-full object-cover"
                        autoPlay
                        muted
                        loop
                        playsInline
                    />
                </div>
            )}

            <div className="absolute inset-x-0 bottom-0 z-10">
                {placeData?.description && (
                    <p className="relative z-20 text-white text-xl md:text-2xl text-center mb-8 max-w-4xl mx-auto p-6"
                       style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}>
                        {placeData.description}
                    </p>
                )}

                <div className="relative z-20 flex flex-col items-center pb-6">
                    <p className="text-indigo-200 text-xl"
                       style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}>
                        Want to experience more history?
                    </p>
                    <AppDownloadButton size="large" />
                </div>
            </div>
        </div>
    );
};

export default HereInHistory; 