import { useNavigate } from 'react-router-dom';

const DiscontinuedMessageScreen = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-black w-screen h-screen">
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50">
        <div className="bg-gray-800 p-8 rounded-lg shadow-lg text-center max-w-md">
          <h2 className="text-2xl font-bold text-white mb-4">Product discontinued</h2>
          <p className="text-gray-300">
            We're sorry, but this product has been discontinued or is no longer available.
          </p>
          <button 
            onClick={() => navigate('/')}
            className="mt-6 bg-purple-600 hover:bg-purple-700 text-white py-2 px-4 rounded transition duration-300"
          >
            Return Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default DiscontinuedMessageScreen;