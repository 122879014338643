import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, auth } from '../Firebase';
import { doc, getDoc } from 'firebase/firestore';
import Map, { Marker, Source, Layer } from 'react-map-gl';
import * as turf from '@turf/turf';
import 'mapbox-gl/dist/mapbox-gl.css';
import { v4 as uuidv4 } from 'uuid';
import './styles/TimePortal.css';
import { useUser } from '../contexts/UserContext';
import * as Sentry from '@sentry/react';

const MAPBOX_TOKEN = 'pk.eyJ1Ijoic3BsYW5rMjYiLCJhIjoiY201a2Y1NTBnMWZqOTJxb21ibm90OWNrZiJ9.6e_UFmi7XUcz1HftFcdcBg'; // You'll need to get this from Mapbox

const PinIcon = ({ color }) => (
    <svg 
        width="24" 
        height="36" 
        viewBox="0 0 24 36" 
        fill={color}
        stroke="white"
        strokeWidth="2"
        style={{ transform: 'translate(-0px, -16px)' }}
    >
        <path d="M12 0C5.373 0 0 5.373 0 12c0 10 12 24 12 24s12-14 12-24c0-6.627-5.373-12-12-12z" />
    </svg>
);

const TimePortal = () => {
    const { id: portalId } = useParams();
    const navigate = useNavigate();
    const [gameId, setGameId] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [viewer, setViewer] = useState(null);
    const [isGuessing, setIsGuessing] = useState(false);
    const [guessStep, setGuessStep] = useState(0);
    const [guessLocation, setGuessLocation] = useState(null);
    const [guessDate, setGuessDate] = useState(0);
    const [guessResult, setGuessResult] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [isHoldingLeft, setIsHoldingLeft] = useState(false);
    const [isHoldingRight, setIsHoldingRight] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [videoMutedStates, setVideoMutedStates] = useState({});
    const [sessionId, setSessionId] = useState(null);
    const [showScoreInfo, setShowScoreInfo] = useState(false);

    // Press references so we can distinguish short press from long press
    const pressStartTime = useRef(null);
    const pressTimer = useRef(null); 
    const holdThreshold = 200; // ms threshold for deciding short vs. long press

    // Timer references so we can cancel pending setTimeout if user releases early
    const leftPressTimer = useRef(null);
    const rightPressTimer = useRef(null);

    const { currentUser, loading: userLoading } = useUser();
    const username = currentUser?.email || currentUser?.uid;

    // Add Pannellum script to document head
    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://cdn.jsdelivr.net/npm/pannellum@2.5.6/build/pannellum.css';
        document.head.appendChild(link);

        const script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/pannellum@2.5.6/build/pannellum.js';
        script.async = true;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(link);
            document.head.removeChild(script);
        };
    }, []);

    useEffect(() => {
        // Fetch portal data
        const fetchData = async () => {
            try {
                const docRef = doc(db, 'timetravel', portalId);
                const docSnap = await getDoc(docRef);
                
                if (docSnap.exists()) {
                    const portalData = docSnap.data();
                    setData(portalData);
                    
                    // Get sessionId from URL params
                    const urlParams = new URLSearchParams(window.location.search);
                    const sessionIdFromUrl = urlParams.get('sessionId');
                    
                    // If this is round 1 and no gameId, generate one
                    if (portalData.round_number === 1) {
                        const newGameId = uuidv4();
                        setGameId(newGameId);
                        
                        // For round 1, either use existing sessionId from URL or generate new one
                        if (sessionIdFromUrl) {
                            setSessionId(sessionIdFromUrl);
                        } else {
                            setSessionId(uuidv4());
                        }
                    } else {
                        // For other rounds, get both gameId and sessionId from URL params
                        const gameIdFromUrl = urlParams.get('gameId');
                        
                        if (!gameIdFromUrl || !sessionIdFromUrl) {
                            // If no gameId or sessionId, redirect to first round
                            if (portalData.first_round) {
                                navigate(`/timeportal/${portalData.first_round}`);
                            } else {
                                console.error('No first round specified');
                            }
                            return;
                        }
                        setGameId(gameIdFromUrl);
                        setSessionId(sessionIdFromUrl);
                    }
                }
                
                setLoading(false);
            } catch (error) {
                Sentry.captureException(error);
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
        
        fetchData();
    }, [portalId, navigate]);

    useEffect(() => {
        if (selectedMedia && !selectedMedia.endsWith('.mp4') && window.pannellum) {
            const viewerInstance = window.pannellum.viewer('panorama', {
                type: 'equirectangular',
                panorama: selectedMedia,
                autoLoad: true,
                compass: false,
                hfov: 110,
                pitch: 0,
                yaw: 180,
                autoRotate: -2,
                showControls: false
            });
            setViewer(viewerInstance);
        } else if (viewer) {
            viewer.destroy();
            setViewer(null);
        }
    }, [selectedMedia]);

    const closeViewer = () => {
        setSelectedMedia(null);
    };

    // Add keyboard event listener for ESC key
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                closeViewer();
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    const muteAllVideos = () => {
        setVideoMutedStates({});  // Reset to empty object = all muted
    };

    const startGuessing = () => {
        muteAllVideos();
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsGuessing(true);
        setGuessStep(0);
    };

    const handleMapClick = (event) => {
        muteAllVideos();
        setGuessLocation({
            longitude: event.lngLat.lng,
            latitude: event.lngLat.lat
        });
    };

    const calculateDistance = () => {
        if (!guessLocation || !data.location) return;

        const from = turf.point([guessLocation.longitude, guessLocation.latitude]);
        const to = turf.point([data.location.longitude, data.location.latitude]);
        const distance = turf.distance(from, to, { units: 'kilometers' });
        const yearDiff = Math.abs(guessDate - data.year);
        console.log("Distance: ", distance, "Year Diff: ", yearDiff);

        const result = {
            distance: Math.round(distance),
            yearDiff: yearDiff
        };
        
        setGuessResult(result);
        return result; // Return the result directly
    };

    const calculateScore = (distanceInKm, guessedYear, actualYear) => {
        // 1) Distance scoring
        //    Perfect 5000 if within 25km,
        //    then less aggressive decay beyond 25km.
        let distanceScore;
        if (distanceInKm <= 25) {
            distanceScore = 5000;
        } else {
            const overDistance = distanceInKm - 25;
            distanceScore = Math.round(5000 * Math.pow(0.999, overDistance));
        }

        // 2) Time scoring with dynamic factor
        const yearDiff = Math.abs(guessedYear - actualYear);
        const baseFactor = (actualYear + 3000) / 5000; 
        const dynamicFactor = 0.5 + (1.0 * baseFactor);

        const timeScore = Math.round(
            5000 * Math.pow(0.99, yearDiff * dynamicFactor)
        );

        const totalScore = Math.max(0, distanceScore) + Math.max(0, timeScore);
        return {
            distanceScore: Math.max(0, distanceScore),
            timeScore: Math.max(0, timeScore),
            totalScore,
        };
    };

    const handleSubmitGuess = async (result) => {
        if (!result) return;
        muteAllVideos();

        const scores = calculateScore(result.distance, guessDate, data.year);

        // Get browser/device info
        const deviceInfo = {
            userAgent: navigator.userAgent,
            language: navigator.language,
            platform: navigator.platform,
            screenResolution: `${window.screen.width}x${window.screen.height}`,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        // Track the guess event
        trackEvent('timeportal_guess', {
            user: username,
            portal_id: portalId,
            game_id: gameId,
            session_id: sessionId,
            round_number: data.round_number,
            score: scores.totalScore,
            distance_km: result.distance,
            year_difference: result.yearDiff,
            guessed_location: guessLocation,
            guessed_year: guessDate,
            actual_location: data.location,
            actual_year: data.year,
            device_info: deviceInfo
        });

        try {
            const response = await fetch('https://timetravel-dd209e3dce1f.herokuapp.com/timeportalguess', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify({
                    game_id: gameId,
                    session_id: sessionId,
                    portal_id: portalId,
                    portal_1_id: data.first_round || portalId,
                    round_number: data.round_number,
                    score: scores.totalScore,
                    guessed_location: guessLocation,
                    guessed_year: guessDate,
                    actual_location: data.location,
                    actual_year: data.year,
                    user: username,
                    device_info: deviceInfo,
                    event_name: data.event_name || data.place || ''
                })
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            setGuessStep(3);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } catch (error) {
            Sentry.captureException(error);
            console.error('Error submitting score:', error);
        }
    };

    const FullScreenVideo = ({ url, onClose }) => {
        const handleClose = () => {
            muteAllVideos();
            onClose();
        };

        const [touchStart, setTouchStart] = useState(null);
        const [touchEnd, setTouchEnd] = useState(null);

        const minSwipeDistance = 50;

        const onTouchStart = (e) => {
            setTouchEnd(null);
            setTouchStart({
                x: e.targetTouches[0].clientX,
                y: e.targetTouches[0].clientY
            });
        };

        const onTouchMove = (e) => {
            setTouchEnd({
                x: e.targetTouches[0].clientX,
                y: e.targetTouches[0].clientY
            });
        };

        const onTouchEnd = () => {
            if (!touchStart || !touchEnd) return;

            const distanceX = touchStart.x - touchEnd.x;
            const distanceY = touchStart.y - touchEnd.y;
            const isLeftSwipe = distanceX > minSwipeDistance;
            const isRightSwipe = distanceX < -minSwipeDistance;
            const isUpSwipe = distanceY > minSwipeDistance;
            const isDownSwipe = distanceY < -minSwipeDistance;

            if (isLeftSwipe || isRightSwipe || isUpSwipe || isDownSwipe) {
                handleClose();
            }
        };

        return (
            <div 
                className="fixed inset-0 bg-black z-50 flex items-center justify-center"
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
            >
                <button 
                    onClick={handleClose}
                    className="absolute top-4 right-4 text-white bg-gray-800 rounded-full w-10 h-10 flex items-center justify-center hover:bg-gray-700 z-10"
                >
                    ✕
                </button>
                <div className="w-full h-full flex items-center justify-center">
                    <video
                        src={url}
                        className="w-full h-full object-contain"
                        controls
                        autoPlay
                        playsInline
                    />
                </div>
            </div>
        );
    };

    const singleYearChange = (direction) => {
        setGuessDate((prevYear) => {
            const current = Number.isFinite(prevYear) ? prevYear : 0;
            const newYear = direction === 'left' ? current - 1 : current + 1;
            return Math.max(-3000, Math.min(2025, newYear));
        });
    };

    const handleDragStart = (e) => {
        if (e.target !== e.currentTarget) {
            return;
        }

        muteAllVideos();
        setIsDragging(true);
        handleDrag(e);
    };

    const handleDrag = (e) => {
        if (!isDragging) return;
        
        const timeline = e.currentTarget;
        const rect = timeline.getBoundingClientRect();
        const x = e.type.includes('touch') ? e.touches[0].clientX - rect.left : e.clientX - rect.left;
        const percentage = Math.max(0, Math.min(1, x / rect.width));
        
        const rawYear = -3000 + (percentage * 5025);
        const newYear = Math.round(rawYear / 1) * 1;
        setGuessDate(newYear);
    };

    const handleDragEnd = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        const handleGlobalPointerUp = () => {
            setIsDragging(false);
        };
        document.addEventListener('pointerup', handleGlobalPointerUp);
        return () => {
            document.removeEventListener('pointerup', handleGlobalPointerUp);
        };
    }, []);

    const imageUrls = data?.image_urls || [];
    const allClues = data?.clues || [];

    const handleMuteToggle = (index) => {
        setVideoMutedStates(prev => {
            const newState = { ...prev };
            
            if (!prev[index]) {
                Object.keys(newState).forEach(key => {
                    newState[key] = false;
                });
            }
            
            newState[index] = !prev[index];
            
            return newState;
        });
    };

    const getResultsUrl = () => {
        return `/timeportalresults?gameId=${gameId}&sessionId=${sessionId}&firstRound=${data.first_round}`;
    };

    const viewTracked = useRef(false);

    useEffect(() => {
        if (!userLoading && data && !viewTracked.current) {
            viewTracked.current = true;
            trackEvent('timeportal_view', {
                user: username,
                portal_id: portalId,
                round_number: data.round_number,
                game_id: gameId,
                session_id: sessionId
            });
        }
    }, [userLoading, data, username, portalId, gameId, sessionId]);

    const trackEvent = async (eventName, eventData) => {
        const data = {
            user: username,
            event_name: eventName,
            manufacturer: 'Web',
            ...eventData,
        };

        fetch('https://timetravel-dd209e3dce1f.herokuapp.com/log', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then((data) => {
            console.log('Success:', data);
        })
        .catch((error) => {
            Sentry.captureException(error);
            console.error('Error:', error);
        });
    };

    return (
        <div className="min-h-screen bg-black p-2 sm:p-4">
            { data ? (
                <>
                    {!isGuessing && (
                        <>
                            <div className="max-w-4xl mx-auto mb-4">
                                <button
                                    onClick={startGuessing}
                                    className="w-full px-8 py-4 text-xl bg-transparent text-white rounded-lg transition-all duration-300 border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4] guess-button"
                                >
                                    Place Your Guess
                                </button>
                            </div>

                            <div className="max-w-4xl mx-auto mt-4 px-0">
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4">
                                    {imageUrls.slice(0, 4).map((url, index) => {
                                        if (!url) return null;
                                        
                                        const rawUrl = url.startsWith('@') ? url.substring(1) : url;
                                        const isVideo = rawUrl.includes('.mp4');
                                        const clue = guessResult && allClues[index] ? allClues[index] : null;
                                        const posterUrl = data?.poster_urls?.[index] || '';
                                        const hasPoster = Boolean(posterUrl && data.poster_urls?.length === 4);

                                        return (
                                            <div
                                                key={index}
                                                className="aspect-square relative"
                                                onClick={() => isVideo && setSelectedVideo(rawUrl)}
                                                style={{ cursor: isVideo ? 'pointer' : 'default' }}
                                            >
                                                <div className="absolute inset-0 bg-[#001f3f] rounded-lg z-0" />
                                                <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent rounded-lg pointer-events-none z-10" />
                                                {isVideo ? (
                                                    <>
                                                        <video
                                                            src={rawUrl}
                                                            className="w-full h-full object-cover rounded-lg relative z-20"
                                                            autoPlay
                                                            loop
                                                            muted={!videoMutedStates[index]}
                                                            playsInline
                                                            controls={false}
                                                            {...(hasPoster && { poster: posterUrl })}
                                                        />
                                                        <div
                                                            className="absolute bottom-2 right-2 z-30 bg-black/50 rounded-full p-2 cursor-pointer hover:bg-black/70"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleMuteToggle(index);
                                                            }}
                                                        >
                                                            {!videoMutedStates[index] ? (
                                                                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2" />
                                                                </svg>
                                                            ) : (
                                                                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
                                                                </svg>
                                                            )}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <img
                                                        src={rawUrl}
                                                        alt={`Media ${index}`}
                                                        className="w-full h-full object-cover rounded-lg relative z-20"
                                                        onError={(e) => console.error('Image error:', e.target.error)}
                                                    />
                                                )}
                                                {clue && (
                                                    <div className="absolute inset-0 bg-black/50 flex items-center justify-center p-4 rounded-lg z-30">
                                                        <p className="text-white text-center text-sm font-medium">
                                                            {clue}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </>
                    )}

                    {isGuessing && (
                        <div className="max-w-4xl mx-auto space-y-4 px-0">
                            {guessResult && (
                                <div className="flex flex-col space-y-2">
                                    <div className="flex justify-between items-center">
                                        <div className="text-white text-lg round-text">
                                            Round {data.round_number} / 5
                                        </div>
                                        {data.next_round ? (
                                            <a
                                                href={`/timeportal/${data.next_round}?gameId=${gameId}&sessionId=${sessionId}`}
                                                className="px-4 py-2 bg-transparent text-white rounded-lg transition-all duration-300 border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4] portal-nav-button"
                                                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                                            >
                                                Next Round →
                                            </a>
                                        ) : (
                                            <a
                                                href={getResultsUrl()}
                                                className="px-4 py-2 bg-transparent text-white rounded-lg transition-all duration-300 border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4] portal-nav-button"
                                                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                                            >
                                                Results →
                                            </a>
                                        )}
                                    </div>
                                    <div className="text-white text-xl px-4 py-2 rounded text-center w-full score-display relative flex items-center justify-center">
                                        Score: {calculateScore(guessResult.distance, guessDate, data.year).totalScore.toLocaleString()} / 10,000
                                        <button 
                                            className="ml-2 text-sm bg-gray-700 rounded-full w-5 h-5 inline-flex items-center justify-center hover:bg-gray-600"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setShowScoreInfo(prev => !prev);
                                            }}
                                        >
                                            ?
                                        </button>
                                        {showScoreInfo && (
                                            <div className="absolute z-50 bg-gray-800 text-white p-4 rounded-lg shadow-lg w-[280px] sm:w-[320px] text-sm left-1/2 transform -translate-x-1/2 mt-2 top-full">
                                                <button 
                                                    className="absolute top-2 right-2 text-gray-400 hover:text-white"
                                                    onClick={() => setShowScoreInfo(false)}
                                                >
                                                    ✕
                                                </button>
                                                <p className="mb-2">Your score is calculated based on two factors:</p>
                                                <p className="mb-2">• Location (5,000 points): Full points if within 10km, decreases with distance</p>
                                                <p>• Time (5,000 points): Full points if exact year, decreases with time difference</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            <div className={`transition-all duration-300 ${guessStep >= 0 ? 'animate-slide-down' : 'hidden'}`}>
                                <h2 className="text-white text-xl sm:text-2xl mb-4 text-center sm:text-left portal-heading">
                                    {!guessResult && "Where were you dropped?"}
                                </h2>
                                <div className="h-[40vh] sm:h-[50vh] rounded-lg overflow-hidden relative">
                                    <Map
                                        mapboxAccessToken={MAPBOX_TOKEN}
                                        initialViewState={{
                                            longitude: guessResult ? data.location.longitude : 0,
                                            latitude: guessResult ? data.location.latitude : 20,
                                            zoom: guessResult ? 3 : 1
                                        }}
                                        minZoom={1.0}
                                        style={{ width: '100%', height: '100%' }}
                                        mapStyle="mapbox://styles/splank26/cm5mwiu0g00dr01qrctisbmd8"
                                        projection="mercator"
                                        onClick={!guessResult ? handleMapClick : undefined}
                                    >
                                        {guessLocation && (
                                            <Marker
                                                longitude={guessLocation.longitude}
                                                latitude={guessLocation.latitude}
                                            >
                                                <PinIcon color="#f97316" />
                                            </Marker>
                                        )}
                                        {guessResult && (
                                            <>
                                                <Marker
                                                    longitude={data.location.longitude}
                                                    latitude={data.location.latitude}
                                                >
                                                    <PinIcon color="#3b82f6" />
                                                </Marker>
                                                <Source type="geojson" data={{
                                                    type: 'Feature',
                                                    geometry: {
                                                        type: 'LineString',
                                                        coordinates: [
                                                            [guessLocation.longitude, guessLocation.latitude],
                                                            [data.location.longitude, data.location.latitude]
                                                        ]
                                                    }
                                                }}>
                                                    <Layer
                                                        type="line"
                                                        paint={{
                                                            'line-color': '#f97316',
                                                            'line-width': 2,
                                                            'line-dasharray': [2, 1]
                                                        }}
                                                    />
                                                </Source>
                                            </>
                                        )}
                                    </Map>
                                    {guessResult && (
                                        <div className="absolute top-4 left-4 z-10">
                                            <div className="actual-info text-xl sm:text-2xl">
                                                <span className="font-normal">Actual Event: </span>
                                                <span className="font-bold">{data.place || data.event_name}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {!guessResult && guessStep === 0 && (
                                    <div className="mt-4 flex justify-between">
                                        <button
                                            onClick={() => setIsGuessing(false)}
                                            className="px-4 py-2 bg-transparent text-white rounded-lg transition-all duration-300 border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4] portal-nav-button"
                                        >
                                            Back
                                        </button>
                                        <button
                                            onClick={() => setGuessStep(1)}
                                            disabled={!guessLocation}
                                            className="px-4 py-2 bg-transparent text-white rounded-lg transition-all duration-300 border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4] portal-nav-button disabled:bg-gray-400 disabled:border-gray-400 disabled:hover:shadow-none"
                                        >
                                            Next
                                        </button>
                                    </div>
                                )}
                            </div>

                            {guessStep >= 1 && (
                                <div className="animate-slide-down bg-gray-800 rounded-lg p-3 sm:p-4">
                                    <h2 className="text-white text-xl sm:text-2xl mb-4 text-center sm:text-left portal-heading">
                                        {!guessResult 
                                            ? "What year were you dropped in?"
                                            : (
                                                <div className="actual-info">
                                                    <span className="font-normal">Actual Year: </span>
                                                    <span className="font-bold">
                                                        {data.year < 0 ? Math.abs(data.year) + " BC" : data.year + " AD"}
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </h2>

                                    <div className="relative w-full h-32">
                                        <div 
                                            className="absolute w-[calc(100%-80px)] h-12 bg-gray-600 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 cursor-grab active:cursor-grabbing rounded"
                                            onMouseDown={!guessResult ? handleDragStart : undefined}
                                            onTouchStart={!guessResult ? handleDragStart : undefined}
                                            onMouseMove={!guessResult ? handleDrag : undefined}
                                            onTouchMove={!guessResult ? handleDrag : undefined}
                                            style={{ touchAction: 'none' }}
                                        >
                                            {!guessResult && (
                                                <button
                                                    className="absolute -left-10 top-1/2 transform -translate-y-1/2 w-8 h-8 bg-transparent text-white rounded-lg transition-all duration-300 border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4] portal-nav-button flex items-center justify-center"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        singleYearChange('left');
                                                    }}
                                                    onContextMenu={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        return false;
                                                    }}
                                                >
                                                    ←
                                                </button>
                                            )}

                                            {!guessResult && (
                                                <button
                                                    className="absolute -right-10 top-1/2 transform -translate-y-1/2 w-8 h-8 bg-transparent text-white rounded-lg transition-all duration-300 border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4] portal-nav-button flex items-center justify-center"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        singleYearChange('right');
                                                    }}
                                                    onContextMenu={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        return false;
                                                    }}
                                                >
                                                    →
                                                </button>
                                            )}

                                            <div 
                                                className={`absolute w-1 h-16 top-1/2 transform -translate-y-1/2 guess-indicator ${guessResult ? 'guessed' : ''}`}
                                                style={{ 
                                                    left: `${((guessDate + 3000) / 5025) * 100}%`,
                                                    backgroundColor: '#f97316'
                                                }}
                                            >
                                                <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                                                    <span className="text-white text-sm font-medium px-1 py-1 rounded" style={{ backgroundColor: '#f97316' }}>
                                                        {guessDate < 0 ? Math.abs(guessDate) + " BC" : guessDate + " AD"}
                                                    </span>
                                                </div>
                                            </div>

                                            {guessResult && (
                                                <div 
                                                    className="absolute w-1 h-16 top-1/2 transform -translate-y-1/2 transition-all duration-300 animate-slide-left"
                                                    style={{ 
                                                        left: `${((data.year + 3000) / 5025) * 100}%`,
                                                        backgroundColor: '#3b82f6'
                                                    }}
                                                >
                                                    <div className="absolute top-20 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                                                        <span className="text-white text-sm font-medium px-1 py-1 rounded" style={{ backgroundColor: '#3b82f6' }}>
                                                            {data.year < 0 ? Math.abs(data.year) + " BC" : data.year + " AD"}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {!guessResult && (
                                        <div className="mt-8 flex justify-between">
                                            <button
                                                onClick={() => setGuessStep(0)}
                                                className="px-4 py-2 bg-transparent text-white rounded-lg transition-all duration-300 border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4] portal-nav-button"
                                            >
                                                Back
                                            </button>
                                            <button
                                                onClick={() => {
                                                    const result = calculateDistance();
                                                    if (result) {
                                                        handleSubmitGuess(result);
                                                    }
                                                }}
                                                className="px-4 py-2 bg-transparent text-white rounded-lg transition-all duration-300 border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4] portal-nav-button"
                                            >
                                                Submit Guess
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}

                            {guessResult && (
                                <div className="animate-slide-down text-center my-6 sm:my-8">
                                    <h2 className="text-white text-2xl sm:text-3xl round-text">Hints</h2>
                                </div>
                            )}

                            <div className="mx-auto mt-4 px-0">
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4">
                                    {imageUrls.slice(0, 4).map((url, index) => {
                                        if (!url) return null;
                                        const isVideo = url.includes('.mp4');
                                        const clue = guessResult && allClues[index] ? allClues[index] : null;

                                        return (
                                            <div 
                                                key={index} 
                                                className="aspect-square relative"
                                                onClick={() => isVideo && setSelectedVideo(url)}
                                                style={{ cursor: isVideo ? 'pointer' : 'default' }}
                                            >
                                                {isVideo ? (
                                                    <>
                                                        <video 
                                                            src={url}
                                                            className="w-full h-full object-cover rounded-lg"
                                                            autoPlay
                                                            loop
                                                            muted={!videoMutedStates[index]}
                                                            playsInline
                                                            controls={false}
                                                        />
                                                        <div 
                                                            className="absolute bottom-2 right-2 z-10 bg-black/50 rounded-full p-2 cursor-pointer hover:bg-black/70"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleMuteToggle(index);
                                                            }}
                                                        >
                                                            {!videoMutedStates[index] ? (
                                                                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2" />
                                                                </svg>
                                                            ) : (
                                                                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
                                                                </svg>
                                                            )}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <img 
                                                        src={url} 
                                                        alt={`Media ${index}`}
                                                        className="w-full h-full object-cover rounded-lg"
                                                    />
                                                )}
                                                {clue && (
                                                    <div className="absolute inset-0 bg-black/50 flex items-center justify-center p-4 rounded-lg">
                                                        <p className="text-white text-center text-sm font-medium">
                                                            {clue}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                    
                                    {guessResult && (
                                        <div className="col-span-1 sm:col-span-2 mt-8">
                                            {data.next_round ? (
                                                <a
                                                    href={`/timeportal/${data.next_round}?gameId=${gameId}&sessionId=${sessionId}`}
                                                    className="block w-full text-center px-6 py-3 bg-transparent text-white rounded-lg transition-all duration-300 border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4] portal-nav-button text-lg"
                                                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                                                >
                                                    Next Round →
                                                </a>
                                            ) : (
                                                <a
                                                    href={getResultsUrl()}
                                                    className="block w-full text-center px-6 py-3 bg-transparent text-white rounded-lg transition-all duration-300 border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4] portal-nav-button text-lg"
                                                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                                                >
                                                    See Final Results →
                                                </a>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    
                    {!isGuessing && (
                        <div className="max-w-4xl mx-auto mt-4">
                            <button
                                onClick={startGuessing}
                                className="w-full px-8 py-4 text-xl bg-transparent text-white rounded-lg transition-all duration-300 border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4] guess-button"
                            >
                                Place Your Guess
                            </button>
                        </div>
                    )}
                </>
            ) : null}
            {selectedVideo && (
                <FullScreenVideo 
                    url={selectedVideo} 
                    onClose={() => setSelectedVideo(null)} 
                />
            )}
        </div>
    );
};

export default TimePortal; 