import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import './styles/EnterTimePortal.css';
import { useUser } from '../contexts/UserContext';
import { db } from '../Firebase';
import { getDocs, collection } from 'firebase/firestore';
import AppDownloadButton from './AppDownloadButton';
import * as Sentry from '@sentry/react';

const EnterTimePortal = () => {
    const [isAnimating, setIsAnimating] = useState(false);
    const navigate = useNavigate();
    const { currentUser, loading: userLoading } = useUser();
    const username = currentUser?.email || currentUser?.uid;
    const [portalData, setPortalData] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [posterUrl, setPosterUrl] = useState(null);
    const [additionalChallenges, setAdditionalChallenges] = useState([]);
    const [allChallenges, setAllChallenges] = useState([]);
    const [currentChallenge, setCurrentChallenge] = useState(null);
    const [utmSource, setUtmSource] = useState(null);

    // New state: only a page of past challenges is displayed
    const PAGE_SIZE = 4;
    const [displayedPastChallenges, setDisplayedPastChallenges] = useState([]);

    // In your component state:
    const [videoLoadState, setVideoLoadState] = useState({}); 
    // This might map challengeId -> boolean; true if loaded, false if still loading

    // Helper to attach an observer to each challenge card
    const challengeRefs = useRef({}); // stores references to each challenge DOM node

    const [copied, setCopied] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const source = queryParams.get('utm_source');
        if (source) {
            setUtmSource(source);
        }
    }, []);

    const trackEvent = async (eventName, eventData) => {
        const data = {
            user: username,
            event_name: eventName,
            manufacturer: 'Web',
            ...eventData,
        };

        if (utmSource) {
            data.utm_source = utmSource;
        }

        fetch('https://timetravel-dd209e3dce1f.herokuapp.com/log', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .catch((error) => {
            Sentry.captureException(error);
        });
    };

    const particlesInit = useCallback(async engine => {
        await loadFull(engine);
    }, []);

    const handleClick = () => {
        setIsAnimating(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        
        if (currentChallenge) {
            trackEvent('timeportal_enter', {
                portal_id: currentChallenge.id
            });

            setTimeout(() => {
                navigate(`/timeportal/${currentChallenge.id}`);
            }, 2500);
        }
    };

    useEffect(() => {
        const fetchAllChallenges = async () => {
            try {
                const challengesRef = collection(db, 'timetravel');
                const querySnapshot = await getDocs(challengesRef);
                const now = new Date();
                
                const validChallenges = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(challenge => 
                        challenge.daily_challenge_date && 
                        challenge.daily_challenge_date.toDate() <= now &&
                        challenge.status === "COMPLETE"
                    )
                    .sort((a, b) => 
                        b.daily_challenge_date.toDate() - a.daily_challenge_date.toDate()
                    );

                if (validChallenges.length > 0) {
                    const mostRecent = validChallenges[0];
                    setCurrentChallenge(mostRecent);
                    setPortalData(mostRecent);
                    
                    if (mostRecent.image_urls?.[0]) {
                        const rawUrl = mostRecent.image_urls[0];
                        const cleanUrl = rawUrl.startsWith('@') ? rawUrl.substring(1) : rawUrl;
                        
                        if (cleanUrl.includes('firebasestorage.googleapis.com')) {
                            const urlObj = new URL(cleanUrl);
                            const pathPart = urlObj.pathname.split('/o/')[1];
                            const cleanPath = decodeURIComponent(pathPart);
                            const properlyEncodedPath = encodeURIComponent(cleanPath);
                            setVideoUrl(`${urlObj.origin}/v0/b/${urlObj.pathname.split('/')[3]}/o/${properlyEncodedPath}${urlObj.search}`);
                        } else {
                            setVideoUrl(cleanUrl);
                        }
                    }

                    if (mostRecent.poster_urls?.[0]) {
                        setPosterUrl(mostRecent.poster_urls[0]);
                    }

                    // Set past challenges (all except the top one)
                    const pastChallenges = validChallenges.slice(1);
                    setAdditionalChallenges(pastChallenges);
                    // Only load the first PAGE_SIZE items initially
                    setDisplayedPastChallenges(pastChallenges.slice(0, PAGE_SIZE));
                }
            } catch (error) {
                Sentry.captureException(error);
            }
        };

        fetchAllChallenges();
    }, []);

    // Function to load more challenges
    const loadMoreChallenges = () => {
        const currentLength = displayedPastChallenges.length;
        const nextItems = additionalChallenges.slice(
            currentLength,
            currentLength + PAGE_SIZE
        );
        if (nextItems.length > 0) {
            setDisplayedPastChallenges([...displayedPastChallenges, ...nextItems]);
        }
    };

    // Intersection Observer setup for infinite scroll
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    loadMoreChallenges();
                }
            },
            { threshold: 0.1 }
        );

        const sentinel = document.querySelector('#scroll-sentinel');
        if (sentinel) {
            observer.observe(sentinel);
        }

        return () => observer.disconnect();
    }, [displayedPastChallenges, additionalChallenges]);

    useEffect(() => {
        if (!additionalChallenges.length) return;

        // Create one observer for all challenge items
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const challengeId = entry.target.getAttribute('data-challengeid');
                        // Mark the challenge as "shouldLoad"
                        setVideoLoadState(prev => ({ ...prev, [challengeId]: true }));
                    }
                });
            },
            { rootMargin: '250px' }  // start loading ~250px before it appears
        );

        // Attach observer to each un-loaded item
        displayedPastChallenges.forEach(challenge => {
            const node = challengeRefs.current[challenge.id];
            if (node && !videoLoadState[challenge.id]) {
                observer.observe(node);
            }
        });

        // Cleanup
        return () => observer.disconnect();
    }, [displayedPastChallenges, videoLoadState, additionalChallenges]);

    const handleCopyLink = async () => {
        try {
            await navigator.clipboard.writeText('https://www.eggnog.ai/trytimeportal');
            setCopied(true);
            setTimeout(() => setCopied(false), 1000);
            
            // Track share event
            trackEvent('timeportal_share', {
                method: 'copy'
            });
        } catch (error) {
            console.error('Failed to copy:', error);
        }
    };

    return (
        <div className="min-h-screen bg-black flex flex-col items-center justify-center p-4 overflow-hidden">
            {isAnimating && (
                <Particles
                    id="tsparticles"
                    init={particlesInit}
                    options={{
                        fullScreen: {
                            enable: true,
                            zIndex: 1
                        },
                        particles: {
                            number: {
                                value: 40,
                                density: {
                                    enable: false,
                                }
                            },
                            color: {
                                value: ["#4682B4", "#87CEEB", "#00BFFF", "#1E90FF"],
                            },
                            shape: {
                                type: ["circle", "line"],
                                options: {
                                    line: {
                                        width: 2
                                    }
                                }
                            },
                            opacity: {
                                value: { min: 0.3, max: 0.8 },
                                animation: {
                                    enable: true,
                                    speed: 5,
                                    minimumValue: 0.1,
                                    sync: false
                                }
                            },
                            size: {
                                value: { min: 1, max: 8 },
                                animation: {
                                    enable: true,
                                    speed: 40,
                                    minimumValue: 0.1,
                                    sync: false
                                }
                            },
                            links: {
                                enable: true,
                                distance: 150,
                                color: "#4682B4",
                                opacity: 0.4,
                                width: 2
                            },
                            move: {
                                enable: true,
                                speed: 80,
                                direction: "none",
                                random: true,
                                straight: false,
                                outModes: {
                                    default: "bounce"
                                },
                                attract: {
                                    enable: true,
                                    rotateX: 1200,
                                    rotateY: 1200
                                }
                            },
                            life: {
                                duration: {
                                    sync: false,
                                    value: 2
                                },
                                count: 1
                            },
                            rotate: {
                                value: {
                                    min: 0,
                                    max: 360
                                },
                                direction: "random",
                                animation: {
                                    enable: true,
                                    speed: 60
                                }
                            },
                            tilt: {
                                direction: "random",
                                enable: true,
                                value: {
                                    min: 0,
                                    max: 360
                                },
                                animation: {
                                    enable: true,
                                    speed: 60
                                }
                            },
                        },
                        interactivity: {
                            detect_on: "canvas",
                            events: {
                                onhover: {
                                    enable: true,
                                    mode: ["grab", "repulse"]
                                },
                                resize: true
                            },
                            modes: {
                                grab: {
                                    distance: 400,
                                    links: {
                                        opacity: 1
                                    }
                                },
                                repulse: {
                                    distance: 200
                                }
                            }
                        },
                        background: {
                            color: "#000"
                        },
                        emitters: [
                            {
                                direction: "none",
                                rate: {
                                    quantity: 15,
                                    delay: 0.3
                                },
                                size: {
                                    width: 100,
                                    height: 100
                                },
                                position: {
                                    x: 50,
                                    y: 50
                                }
                            }
                        ]
                    }}
                />
            )}
            
            <h1 className="text-white text-4xl md:text-6xl text-center mb-12 font-bold relative z-10 tracking-wider glowing-text cyberpunk-text">
                TIME PORTAL
            </h1>
            <p className="text-white text-xl md:text-2xl text-center mb-12 relative z-10 tracking-wide glowing-text-subtle">
                Travel through time and figure out which events you landed in
            </p>
            
            <div className="w-full max-w-4xl mx-auto mb-12">
                <div
                    className="relative w-full aspect-square md:aspect-video rounded-lg overflow-hidden cursor-pointer"
                    onClick={handleClick}
                >
                    {/* Dark-blue background behind the video (lowest layer) */}
                    <div className="absolute inset-0 bg-[#001f3f] rounded-lg z-0" />
                    
                    {videoUrl && (
                        <video
                            src={videoUrl}
                            poster={posterUrl}
                            className="absolute inset-0 w-full h-full object-cover rounded-lg z-10"
                            autoPlay
                            loop
                            muted
                            playsInline
                        />
                    )}

                    {/* Gradient overlay above the video */}
                    <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent rounded-lg z-20" />
                    
                    {/* Button above everything else */}
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30">
                        <button
                            onClick={handleClick}
                            className={`px-8 py-4 text-xl bg-transparent text-white rounded-lg transition-all duration-300 
                                border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4]
                                tracking-wider ${isAnimating ? 'animating lightning-pulse explosion' : ''}`}
                        >
                            PLAY DAILY CHALLENGE
                        </button>
                    </div>
                </div>
            </div>

            <div className="w-full max-w-4xl mb-12 text-center">
                <p className="text-white text-xl md:text-2xl mb-6 relative z-10 tracking-wide glowing-text-subtle">
                    Download the app to play more challenges!
                </p>
                <div className="flex justify-center">
                    <AppDownloadButton />
                </div>
            </div>

            <div className="w-1/2 max-w-4xl mb-12">
                <div className="space-y-2">
                    <h2 className="text-white text-xl md:text-2xl text-center mb-2 relative z-10 tracking-wide glowing-text-subtle">
                        Share Timeportal:
                    </h2>

                    <div className="bg-gray-900 p-3 rounded-lg flex flex-col sm:flex-row sm:justify-between sm:items-center gap-3 group relative">
                        {/* Code and Button Wrapper */}
                        <div className="flex items-center justify-between w-full overflow-hidden">
                            <code 
                                className="text-gray-600 text-center sm:text-left flex-1 overflow-hidden text-ellipsis whitespace-nowrap"
                            >
                                https://www.eggnog.ai/trytimeportal
                            </code>
                            <button
                                onClick={handleCopyLink}
                                className={`
                                    ml-3 px-4 py-2 rounded-md 
                                    transition-all duration-300 
                                    ${copied 
                                        ? 'bg-green-400 text-black' 
                                        : 'bg-gray-700 hover:bg-gray-600 text-gray-200'
                                    }
                                    transform active:scale-95
                                    self-center sm:self-end
                                    whitespace-nowrap
                                `}
                            >
                                {copied ? 'Copied!' : 'Copy'}
                            </button>
                        </div>

                        {/* Success message overlay */}
                        <div className={`
                            absolute inset-0 bg-green-400/10 backdrop-blur-md 
                            flex items-center justify-center rounded-lg
                            transition-all duration-300 ease-out
                            ${copied ? 'opacity-100' : 'opacity-0 pointer-events-none'}
                        `}>
                            <div className="flex items-center gap-2 text-green-400">
                                <span className="animate-[bounce_0.5s_ease-in-out]">✓</span>
                                <span className="animate-[fadeIn_0.3s_ease-out]">Copied</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full max-w-4xl">
                <h2 className="text-white text-xl md:text-2xl text-left mb-8 relative z-10 tracking-wide glowing-text-subtle">
                    PAST CHALLENGES
                </h2>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {displayedPastChallenges.map((challenge) => (
                        <div 
                            key={challenge.id}
                            data-challengeid={challenge.id}
                            ref={(el) => (challengeRefs.current[challenge.id] = el)} 
                            className="relative aspect-square rounded-lg overflow-hidden cursor-pointer group"
                            onClick={() => {
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                trackEvent('timeportal_enter', {
                                    portal_id: challenge.id
                                });
                                navigate(`/timeportal/${challenge.id}`);
                            }}
                        >
                            {/* The dark-blue background at the bottom */}
                            <div className="absolute inset-0 bg-[#001f3f] rounded-lg z-0" />

                            {videoLoadState[challenge.id] ? (
                                <video
                                    src={challenge.image_urls[0].startsWith('@') 
                                        ? challenge.image_urls[0].substring(1) 
                                        : challenge.image_urls[0]}
                                    poster={challenge.poster_urls?.[0]}
                                    className="absolute inset-0 w-full h-full object-cover transition-transform group-hover:scale-105 z-10"
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    preload="metadata"
                                />
                            ) : (
                                challenge.poster_urls?.[0] && (
                                    <img
                                        src={challenge.poster_urls[0]}
                                        alt="Challenge Poster"
                                        className="absolute inset-0 w-full h-full object-cover transition-transform group-hover:scale-105 z-10"
                                    />
                                )
                            )}

                            {/* Gradient overlay */}
                            <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent rounded-lg z-20" />
                            
                            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30">
                                <button
                                    className="px-8 py-4 text-xl bg-transparent text-white rounded-lg transition-all duration-300 
                                        border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4]
                                        tracking-wider uppercase flex flex-col items-center gap-2 whitespace-nowrap"
                                >
                                    <span className="whitespace-nowrap">
                                        {challenge.daily_challenge_date 
                                            ? challenge.daily_challenge_date.toDate().toLocaleDateString('en-US', { 
                                                month: 'long', 
                                                day: 'numeric', 
                                                year: 'numeric' 
                                              })
                                            : 'Time Portal'}
                                    </span>
                                    <span className="text-sm">CHALLENGE</span>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Sentinel element for infinite scroll */}
                {displayedPastChallenges.length < additionalChallenges.length && (
                    <div 
                        id="scroll-sentinel" 
                        className="h-10 w-full"
                    />
                )}
            </div>
        </div>
    );
};

export default EnterTimePortal; 