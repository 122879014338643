import { useEffect, useState } from "react";
import { motion } from "framer-motion";

const words = ["Spongebob", "Sopranos", "Trump", "Seinfeld", "Friends"];
const scrambleInterval = 75; // Speed of letter flickering
const revealDuration = 2000; // Time taken to settle on final word

const ScrambledText = () => {
  const [currentWord, setCurrentWord] = useState(words[0]);
  const [displayText, setDisplayText] = useState("_".repeat(words[0].length));

  useEffect(() => {
    let wordIndex = 0;

    const shuffleText = (targetWord) => {
      let currentText = "_".repeat(targetWord.length);
      let iterations = 0;

      const interval = setInterval(() => {
        currentText = currentText
          .split("")
          .map((char, i) =>
            Math.random() > 0.5 || iterations < targetWord.length
              ? String.fromCharCode(65 + Math.floor(Math.random() * 26)) // Random letters A-Z
              : targetWord[i]
          )
          .join("");

        setDisplayText(currentText);

        iterations++;
        if (iterations > targetWord.length * 2) {
          clearInterval(interval);
          setDisplayText(targetWord);
        }
      }, scrambleInterval);
    };

    const cycleWords = () => {
      wordIndex = (wordIndex + 1) % words.length;
      setCurrentWord(words[wordIndex]);
      shuffleText(words[wordIndex]);
    };

    shuffleText(words[wordIndex]);
    const wordCycleInterval = setInterval(cycleWords, revealDuration + 50); // Time to display the final word

    return () => clearInterval(wordCycleInterval);
  }, []);

  return (
    <span className="text-black font-mono text-lg sm:text-xl tracking-wide bg-[#E4E132]  border-radius-lg mx-1 px-1">
      {displayText}
    </span>
  );
};

const AppPromotionWidget = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
      className="relative bg-black text-[#E4E132] rounded-lg px-5 py-4 flex flex-col sm:flex-row items-center gap-4 shadow-xl border border-[#E4E132]/40 overflow-hidden my-2"
    >
      {/* Floating Background Elements */}
      {[...Array(5)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute w-8 h-8 bg-[#E4E132]/20 rounded-full blur-xl"
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
          }}
          animate={{
            y: [0, Math.random() * 30 - 15, 0],
            x: [0, Math.random() * 30 - 15, 0],
            opacity: [0.5, 0.8, 0.5],
          }}
          transition={{
            duration: 5 + Math.random() * 3,
            repeat: Infinity,
            repeatType: "mirror",
          }}
        />
      ))}

      {/* Light Pulsing Background Effect */}
      <motion.div
        className="absolute inset-0 pointer-events-none"
        animate={{
          background: [
            "radial-gradient(circle at 30% 50%, rgba(228, 225, 50, 0.1) 0%, transparent 50%)",
            "radial-gradient(circle at 70% 50%, rgba(228, 225, 50, 0.1) 0%, transparent 50%)",
            "radial-gradient(circle at 30% 50%, rgba(228, 225, 50, 0.1) 0%, transparent 50%)",
          ],
        }}
        transition={{ duration: 6, repeat: Infinity, repeatType: "reverse" }}
      />

      {/* Text Content with Scrambling Effect */}
      <div className="flex-1 text-center sm:text-left">
        <h3 className="text-sm font-bold tracking-tight mb-2">
          Try the app!
        </h3>
        <h3 className="text-lg sm:text-xl font-bold tracking-tight">
          Remix <ScrambledText /> with the SceneMix app
        </h3>
        <p className="text-sm text-[#E4E132]/80">
          Remix & create anywhere. Download now!
        </p>
      </div>

      {/* Official App Store Button */}
      <motion.a
        href="https://apps.apple.com/us/app/scenemix-voice-ai/id6742719343"
        target="_blank"
        rel="noopener noreferrer"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="h-10 sm:h-12"
      >
        <img 
          src="/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg"
          alt="Download on App Store"
          className="h-full w-auto"
        />
      </motion.a>
    </motion.div>
  );
};

export default AppPromotionWidget;
