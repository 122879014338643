import React, { useState, useEffect } from 'react';
import { db } from '../Firebase';
import { doc, getDoc } from 'firebase/firestore';
import * as Sentry from '@sentry/react';
import { v4 as uuidv4 } from 'uuid';
import './styles/FamilyHistory.css';
import AppDownloadButton from './AppDownloadButton';

const FamilyHistory = () => {
    const [lastName, setLastName] = useState('');
    const [loading, setLoading] = useState(false);
    const [historyText, setHistoryText] = useState(null);
    const [error, setError] = useState(null);
    const [deviceId, setDeviceId] = useState(null);

    // Initialize device ID
    useEffect(() => {
        // Try to get existing device ID from localStorage
        let storedDeviceId = localStorage.getItem('timeportal_device_id');
        if (!storedDeviceId) {
            // If none exists, create new one
            storedDeviceId = uuidv4();
            localStorage.setItem('timeportal_device_id', storedDeviceId);
        }
        setDeviceId(storedDeviceId);
    }, []);

    // Function to sanitize name for Firebase doc ID
    const sanitizeName = (name) => {
        return name.toLowerCase()
            .replace(/[^a-z0-9]/g, '') // Remove special characters
            .trim();
    };

    // Function to poll Firebase for results
    const pollForResults = async (sanitizedName) => {
        try {
            const docRef = doc(db, 'family-names', sanitizedName);
            
            const checkDocument = async () => {
                const docSnap = await getDoc(docRef);
                
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    if (data.status === 'COMPLETE') {
                        setHistoryText(data.history_text);
                        setLoading(false);
                        return true;
                    } else if (data.status === 'ERROR') {
                        setError('An error occurred while processing your request.');
                        setLoading(false);
                        return true;
                    }
                }
                return false;
            };

            // Poll every 2 seconds for up to 30 seconds
            let attempts = 0;
            const maxAttempts = 15;
            
            const pollInterval = setInterval(async () => {
                attempts++;
                const isComplete = await checkDocument();
                
                if (isComplete || attempts >= maxAttempts) {
                    clearInterval(pollInterval);
                    if (attempts >= maxAttempts && !isComplete) {
                        setError('Request timed out. Please try again.');
                        setLoading(false);
                    }
                }
            }, 2000);

        } catch (error) {
            Sentry.captureException(error);
            setError('An error occurred while fetching results.');
            setLoading(false);
        }
    };

    // Update trackEvent function to include device_id
    const trackEvent = async (eventName, eventData) => {
        if (!deviceId) return; // Don't track if no device ID yet
        
        try {
            const response = await fetch('https://timetravel-dd209e3dce1f.herokuapp.com/log', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    event_name: eventName,
                    manufacturer: 'Web',
                    device_id: deviceId,
                    ...eventData,
                })
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            Sentry.captureException(error);
            console.error('Error tracking event:', error);
        }
    };

    // Update view tracking to only fire when deviceId is available
    useEffect(() => {
        if (deviceId) {
            trackEvent('family_history_view', {});
        }
    }, [deviceId]);  // Changed dependency from [] to [deviceId]

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!lastName.trim()) return;

        setLoading(true);
        setError(null);
        setHistoryText(null);

        const sanitizedName = sanitizeName(lastName);

        // Track the search attempt
        trackEvent('family_history_search', {
            last_name: lastName.trim(),
            sanitized_name: sanitizedName
        });

        try {
            // Check if document already exists
            const docRef = doc(db, 'family-names', sanitizedName);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                if (data.status === 'COMPLETE') {
                    setHistoryText(data.history_text);
                    setLoading(false);
                    return;
                }
            }

            // If document doesn't exist or isn't complete, make the API request
            const response = await fetch('https://timetravel-dd209e3dce1f.herokuapp.com/family-name', {
            // const response = await fetch('http://127.0.0.1:2999/family-name', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    sanitized_name: sanitizedName,
                    raw_name: lastName.trim()
                })
            });

            if (!response.ok) {
                throw new Error('Failed to submit request');
            }

            // Start polling for results
            pollForResults(sanitizedName);

        } catch (error) {
            Sentry.captureException(error);
            console.error('Error submitting request:', error);
            setError('Failed to submit request. Please try again.');
            setLoading(false);

            // Track the error
            trackEvent('family_history_error', {
                last_name: lastName.trim(),
                sanitized_name: sanitizedName,
                error: error.message
            });
        }
    };

    return (
        <div className="min-h-screen bg-black p-2 sm:p-4">
            <div className="max-w-4xl mx-auto space-y-4 px-0">
                <h1 className="text-white text-3xl sm:text-4xl text-center mb-8 portal-heading">
                    Find out the truth about your family
                </h1>

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="relative">
                        <input
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder="Enter your last name"
                            className="w-full px-4 py-3 bg-gray-800 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-[#4682B4] transition-all duration-300"
                            disabled={loading}
                            maxLength={50}
                        />
                    </div>

                    <button
                        type="submit"
                        disabled={loading || !lastName.trim()}
                        className="w-full px-8 py-4 text-xl bg-transparent text-white rounded-lg transition-all duration-300 border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4] portal-nav-button"
                    >
                        {loading ? 'Researching...' : 'Go'}
                    </button>
                </form>

                {loading && (
                    <div className="text-center py-8">
                        <div className="inline-block animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#4682B4]"></div>
                    </div>
                )}

                {error && (
                    <div className="text-red-500 text-center py-4">
                        {error}
                    </div>
                )}

                {historyText && (
                    <>
                        <div className="animate-slide-down bg-gray-800 rounded-lg p-6 mt-8">
                            <p className="text-white text-lg leading-relaxed whitespace-pre-line">
                                {historyText}
                            </p>
                        </div>
                        
                        <div className="animate-slide-down bg-gray-800 rounded-lg p-6 mt-4">
                            <h2 className="text-white text-xl mb-4 text-center">
                                Want to experience the history your ancestors lived through?
                            </h2>
                            <div className="w-full flex justify-center">
                                <AppDownloadButton />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default FamilyHistory;