import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../Firebase.js';
import { getEndpoint } from '../utils';
import RelatedVideos from './RelatedVideos';
import { logEvent } from "../utils";
import { auth } from '../Firebase.js';
import { signInAnonymously } from 'firebase/auth';
import { useUser } from '../contexts/UserContext';
import { Helmet } from 'react-helmet';
import AppPromotion from './AppPromotion';
// import MixShareDownload from '../../../components/MixShareDownload'

const MixView = () => {
  const { document_id } = useParams();
  const [remixStatus, setRemixStatus] = useState(null);
  const [completedVideo, setCompletedVideo] = useState(null);
  const videoRef = React.useRef(null);
  
  // Email notification states
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [originalSceneId, setOriginalSceneId] = useState(false);
  const [originalSceneTitle, setOriginalSceneTitle] = useState(false);
  const [copied, setCopied] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [docExists, setDocExists] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);

  const [viewCount, setViewCount] = useState(0);
  const [completedAt, setCompletedAt] = useState(null);
  const [previewGifUrl, setPreviewGifUrl] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Remix'; // Set tab title for Home page
  }, []);
  const { currentUser, loading: userLoading } = useUser();
  const username = currentUser?.email || currentUser?.uid;

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, 'remixes', document_id), (docSnap) => {
      setIsLoading(false);

      if (!docSnap.exists()) {
        setDocExists(false);
        return;
      }

      setDocExists(true);
      const remixData = docSnap.data() || {};
      console.log(remixData)

      // Existing fields
      if (remixData.original_scene?.id) {
        setOriginalSceneId(remixData.original_scene.id);
      }
      if (remixData.original_scene?.title) {
        setOriginalSceneTitle(remixData.original_scene.title);
      }
      if (remixData.original_scene?.gif_url) {
        setPreviewGifUrl(remixData.original_scene.gif_url);
      }
      if (remixData.status) {
        setRemixStatus(remixData.status);
      }
      if (remixData.completed_video_url) {
        setCompletedVideo(remixData.completed_video_url);
      }

      if (remixData.thumbnail_url) {
        setThumbnailUrl(remixData.thumbnail_url);
      }

      // New metadata fields
      setViewCount(remixData.view_count || 0);
      setCompletedAt(remixData.completed_at);
    }, (error) => {
      console.error("Firestore onSnapshot error:", error);
      setIsLoading(false);
      setDocExists(false);
    });

    return () => unsubscribe();
  }, [document_id]);

  useEffect(() => {
    const checkAndSignInAnonymously = async () => {
      if (!currentUser && !userLoading) {
        try {
          await signInAnonymously(auth);
        } catch (error) {
          console.error('Error during anonymous sign-in:', error);
        }
      }
    };

    checkAndSignInAnonymously();
  }, [currentUser, userLoading]);

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!isValidEmail(email)) return;

    setIsSubmitting(true);
    try {
      const response = await fetch(`${getEndpoint()}/notify-remix-completion`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          document_id,
          email
        })
      });

      if (response.ok) {
        setEmailSubmitted(true);
        setShowEmailForm(false);
      }
    } catch (error) {
      console.error('Error submitting email:', error);
    }
    setIsSubmitting(false);
  };

  const shareUrl = `https://www.eggnog.ai/mix/${document_id}`;
  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 1000); // Reset after 2 seconds
      await logEvent({
        eventName: 'remix-mixview-copy-link',
        video: shareUrl,
        originalSceneTitle: originalSceneTitle,
        eventName: 'remix-homepage-copy-link',
        user: username

      });
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const hasError = remixStatus && remixStatus == 'ERROR';
  // 1) Loading State
  if (isLoading) {
    return (
      <>
      <Helmet>
        <title>Loading Remix...</title>
        <meta name="description" content="Loading video remix" />
      </Helmet>
      <div className="min-h-screen bg-black flex flex-col items-center justify-center gap-4">
        {/* Replace with your own spinner or loading indicator */}
        <p className="text-gray-400 text-lg">Loading…</p>
      </div>
      </>
    );
  }

  // 2) Document Does Not Exist
  if (!docExists) {
    return (
      <>
      <Helmet>
        <title>Remix Not Found</title>
        <meta name="description" content="This remix could not be found" />
      </Helmet>
      <div className="min-h-screen bg-black flex flex-col items-center justify-center gap-4">
        <p className="text-gray-400 text-lg">
          Hm, we couldn't find a remix with that ID
        </p>
        <a 
          href="https://www.eggnog.ai/remix" 
          className="text-green-400 hover:text-green-300 transition-colors"
        >
          Go home
        </a>
      </div>
      </>
    );
  }

  const VideoMetaTags = ({ 
    originalSceneTitle, 
    viewCount, 
    completedVideo, 
    previewGifUrl, 
    completedAt, 
    shareUrl 
  }) => {
    return (
      <Helmet>
        {/* Basic tags */}
        <title>{originalSceneTitle ? `${originalSceneTitle} Remix` : 'Video Remix'}</title>
        <meta name="description" content={`Watch this custom remix${originalSceneTitle ? ` of ${originalSceneTitle}` : ''} ${viewCount ? `(${viewCount.toLocaleString()} views)` : ''}`} />
        
        {/* OpenGraph tags */}
        <meta property="og:title" content={originalSceneTitle ? `${originalSceneTitle} Remix` : 'Video Remix'} />
        <meta property="og:description" content={`Watch this custom remix${originalSceneTitle ? ` of ${originalSceneTitle}` : ''} ${viewCount ? `(${viewCount.toLocaleString()} views)` : ''}`} />
        <meta property="og:type" content="video.other" />
        <meta property="og:url" content={shareUrl} />
        
        {/* Show either completed video or preview GIF */}
        {completedVideo ? (
          [
            <meta key="og-video" property="og:video" content={completedVideo} />,
            <meta key="og-video-type" property="og:video:type" content="video/mp4" />,
            <meta key="og-video-width" property="og:video:width" content="1920" />,
            <meta key="og-video-height" property="og:video:height" content="1080" />,
            <meta key="twitter-player" name="twitter:player" content={completedVideo} />,
            <meta key="twitter-player-width" name="twitter:player:width" content="1920" />,
            <meta key="twitter-player-height" name="twitter:player:height" content="1080" />
          ]
        ) : previewGifUrl ? (
          [
            <meta key="og-image" property="og:image" content={previewGifUrl} />,
            <meta key="og-image-type" property="og:image:type" content="image/gif" />,
            <meta key="twitter-image" name="twitter:image" content={previewGifUrl} />
          ]
        ) : null}

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="player" />
        <meta name="twitter:title" content={originalSceneTitle ? `${originalSceneTitle} Remix` : 'Video Remix'} />
        <meta name="twitter:description" content={`Watch this custom remix${originalSceneTitle ? ` of ${originalSceneTitle}` : ''} ${viewCount ? `(${viewCount.toLocaleString()} views)` : ''}`} />
        

        {completedAt && (
          <meta property="article:published_time" content={completedAt} />
        )}
      </Helmet>
    );
  };
  console.log("loading", isLoading)
  console.log("error:", hasError)
  console.log("remixStatus:", remixStatus)
if (isLoading) {
    return (
      <>
        <VideoMetaTags 
          originalSceneTitle={originalSceneTitle}
          viewCount={viewCount}
          completedVideo={completedVideo}
          previewGifUrl={previewGifUrl}
          thumbnailUrl={thumbnailUrl}
          completedAt={completedAt}
          shareUrl={shareUrl}
        />
        <div className="min-h-screen bg-black flex flex-col items-center justify-center gap-4">
          <p className="text-gray-400 text-lg">Loading…</p>
        </div>
      </>
    );
  }

  if (!docExists) {
    return (
      <>
        <VideoMetaTags 
          originalSceneTitle={originalSceneTitle}
          viewCount={viewCount}
          completedVideo={completedVideo}
          previewGifUrl={previewGifUrl}
          thumbnailUrl={thumbnailUrl}
          completedAt={completedAt}
          shareUrl={shareUrl}
        />
        <div className="min-h-screen bg-black flex flex-col items-center justify-center gap-4">
          <p className="text-gray-400 text-lg">
            Hm, we couldn't find a remix with that ID
          </p>
          <a 
            href="https://www.eggnog.ai/remix" 
            className="text-green-400 hover:text-green-300 transition-colors"
          >
            Go home
          </a>
        </div>
      </>
    );
  }
  if (hasError) {
    return (
      <>
      <VideoMetaTags 
        originalSceneTitle={originalSceneTitle}
        viewCount={viewCount}
        completedVideo={completedVideo}
        previewGifUrl={previewGifUrl}
        completedAt={completedAt}
        shareUrl={shareUrl}
      />
      <div className="min-h-screen bg-black text-white flex flex-col items-center justify-center p-4">
        {/* Video creation placeholder */}
        <div className="w-full max-w-2xl">
          <AppPromotion />
        </div>
        <div className={`w-full max-w-2xl aspect-video bg-gray-900 rounded-lg mb-8 mt-4 flex flex-col items-center justify-center ${hasError ? 'border-2 border-red-400' : ''}`}>
            <p className="text-lg text-gray-400 text-center px-6">
              Sorry, we encountered an error making your video. Try using a different template or try again later.
            </p>
            <a 
              href="/remix" 
              className="mt-6 px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-700 transition-colors duration-300"
            >
              ← Make something else
            </a>
        </div>

      </div>
      </>
    );    
  }
  if (!completedVideo) {
    return (
      <>
        <VideoMetaTags 
          originalSceneTitle={originalSceneTitle}
          viewCount={viewCount}
          completedVideo={completedVideo}
          previewGifUrl={previewGifUrl}
          thumbnailUrl={thumbnailUrl}
          completedAt={completedAt}
          shareUrl={shareUrl}
        />
        <div className="min-h-screen bg-black text-white flex flex-col items-center justify-center p-4">
          <AppPromotion />
          <div className={`w-full max-w-2xl aspect-video bg-gray-900 rounded-lg mb-8 flex flex-col items-center justify-center ${hasError ? 'border-2 border-red-400' : ''}`}>
            <>
              <p className="text-xl text-gray-400 animate-pulse">
                Making your video
              </p>
              <p className="text-gray-600 mt-2">
                {remixStatus}
              </p>
              <a
                href="/remix"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-400 hover:text-white transition my-8 cursor-pointer inline-block font-bold"
              >
                Watch while you wait →
              </a>
            </>
          </div>

          <div className="w-full max-w-2xl">
            {!hasError && (
              !emailSubmitted ? (
                <div className="space-y-4">
                  <button
                    onClick={() => setShowEmailForm(true)}
                    className="text-gray-500 hover:text-white transition-colors duration-200"
                  >
                    Tell me when my video is ready →
                  </button>

                  {showEmailForm && (
                    <form onSubmit={handleEmailSubmit} className="space-y-3">
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        className="w-full p-3 rounded-lg bg-gray-800 border border-gray-700 focus:border-gray-500 focus:outline-none transition text-gray-100 placeholder-gray-400"
                      />
                      <button
                        type="submit"
                        disabled={!isValidEmail(email) || isSubmitting}
                        className="px-4 py-2 bg-blue-600 hover:bg-blue-700 disabled:bg-gray-700 disabled:cursor-not-allowed rounded-lg transition-colors duration-200"
                      >
                        {isSubmitting ? 'Submitting...' : 'Email me'}
                      </button>
                    </form>
                  )}
                </div>
              ) : (
                <p className="text-gray-500">
                  We will send an email to {email} once your video is ready.
                </p>
              )
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <VideoMetaTags 
        originalSceneTitle={originalSceneTitle}
        viewCount={viewCount}
        completedVideo={completedVideo}
        previewGifUrl={previewGifUrl}
        thumbnailUrl={thumbnailUrl}
        completedAt={completedAt}
        shareUrl={shareUrl}
      />
      <div className="min-h-screen bg-black text-white">
        <header className="sticky top-0 bg-black/90 backdrop-blur-sm p-4 z-10">
          <h1 className="text-2xl font-bold text-center">
            <a 
              href="http://www.eggnog.ai/remix" 
              className="hover:text-gray-300 transition-colors inline-flex items-center gap-2"
            >
              🥚 remix
            </a>
          </h1>
          <h2 className="text-xs sm:text-md text-center text-gray-400 mt-2">
          Remix popular movie scenes, TV shows, and current events ✨
          </h2>
        </header>      
        <div className="max-w-4xl mx-auto p-4">
          {/*{originalSceneTitle && <div className='text-center'><p className='text-2xl text-gray-300 font-bold my-4'>{originalSceneTitle}</p></div>}*/}
          <AppPromotion />
          <video
            ref={videoRef}
            src={completedVideo}
            controls
            autoPlay
            onLoadedData={() => {
              if (videoRef.current) {
                videoRef.current.volume = 1;
              }
            }}
            className="w-full rounded-lg shadow-lg"
          />
          
          <div className="space-y-2 mt-8">
            <div className='w-full'>
            <div className="w-full text-center mb-8">
              <button 
                onClick={async () => {
                  window.open(
                    `https://www.eggnog.ai/remix/${originalSceneId}`,
                    "_blank",
                    "noopener,noreferrer"
                  );
                  await logEvent({
                    eventName: "remix-mixview-create",
                    video: shareUrl,
                    originalSceneTitle: originalSceneTitle,
                    user: 'anon-remix-viewer'

                  });
                }}
                className="w-full group relative inline-flex items-center justify-center px-8 py-4 text-lg font-medium overflow-hidden rounded-lg bg-gray-800 backdrop-blur-sm transition-all duration-300 ease-out hover:scale-105"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-green-400/0 via-green-400/30 to-green-400/0 animate-[shimmer_2s_linear_infinite]" />
                <span className="relative text-white flex items-center gap-2">
                  Remix this scene
                  <span className="text-green-400 font-bold inline-block transition-transform group-hover:translate-x-1 animate-[slideRight_2s_ease-in-out_infinite]">
                    →
                  </span>
                </span>
              </button>
            </div>
            </div>


          
            {/*<p className="mt-8 text-gray-400 text-xs">Share this remix:</p>

            <MixShareDownload 
              shareUrl={shareUrl}
              videoUrl={completedVideo}
              originalSceneTitle={originalSceneTitle}
            />*/}
          </div>


          <RelatedVideos 
            originalSceneId={originalSceneId} 
            videoId={document_id} 
            originalSceneTitle={originalSceneTitle}
          />

          <div className='w-full text-center'>
          <button
            className="w-full my-2 py-3 rounded-lg text-white font-bold text-md hover:text-green-400 hover:scale-105 duration-300"
            onClick={async () => {
              window.open(
                `https://www.eggnog.ai/remix`,
                "_blank",
                "noopener,noreferrer"
              );
            }}
          >
            Watch more remixes →
          </button>
                    
          </div>
          
          <style jsx>{`
            @keyframes shimmer {
              0% { transform: translateX(-100%); }
              100% { transform: translateX(100%); }
            }
            
            @keyframes slideRight {
              0%, 100% { transform: translateX(0px); }
              50% { transform: translateX(4px); }
            }
          `}</style>
        </div>
      </div>   
    </>
  );
}


export default MixView;