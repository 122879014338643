import React, { useState, useEffect } from 'react';

function TermsOfService() {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    fetch('/remix_tos.html') // Ensure the file is in the `public/` folder
      .then(response => response.text())
      .then(html => setHtmlContent(html))
      .catch(error => console.error('Error loading TOS:', error));
  }, []);

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', lineHeight: '1.6' }}>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
}

export default TermsOfService;
