import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { db } from '../Firebase.js';
import {
  doc,
  onSnapshot
} from 'firebase/firestore';

import { getEndpoint, logEvent } from '../utils';
import RelatedVideos from './RelatedVideos';

import { Loader } from 'lucide-react';
import { Sparkle } from 'lucide-react';
import { ArrowRight } from 'lucide-react';
import { Ellipsis } from 'lucide-react';
import { Check } from 'lucide-react';
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";

import { v4 as uuidv4 } from 'uuid';

/* =========================
    CHILD COMPONENTS
   ========================= */

/**
 * Basic (Standard) Remix Editor
 */
const BasicRemixEditor = ({
  scene,
  lines,
  setLines,
  username,
  navigate
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const CHARACTER_LIMIT = 300;

  // Handle text input changes
  const handleTextChange = (index, value) => {
    setLines(prev => {
      const newLines = [...prev];
      newLines[index].text = value.slice(0, CHARACTER_LIMIT);
      return newLines;
    });
  };

  // Simple placeholder logic
  const getPlaceholder = (line) => {
    if (line.original_text) {
      return `${line.original_text.slice(
        0,
        100 + line.original_text.slice(100).indexOf(' ')
      )}...`;
    } else {
      return 'Enter text';
    }
  };

  // Submit final Basic remix
  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await logEvent({
        eventName: 'remix-submit-remix',
        sceneId: scene.id,
        title: scene.title,
        user: username,
      });

      const response = await fetch(`${getEndpoint()}/remix-scene`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          scene_document: scene,
          lines: lines,
        }),
      });

      if (response.ok) {
        const { document_id } = await response.json();
        navigate(`/mix/${document_id}`);
      }
    } catch (error) {
      console.error('Error submitting remix:', error);
    }
    setIsSubmitting(false);
  };

  // Determine if we can submit (all lines should have text)
  const canSubmit = lines.every(line => line.text.trim().length > 0);

  return (
    <div className="space-y-4">
      {lines.map((line, index) => (
        <div
          key={index}
          className="bg-gray-900 px-3 p-4 rounded-lg border border-gray-800 transition-all hover:border-gray-700"
        >
          <div className="flex justify-between mb-2">
            <span className="text-gray-400">
              {line.speaker_id
                .split(/[_-]/)
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ')}
            </span>
            <span className="text-gray-400">{line.duration.toFixed(1)}s</span>
          </div>
          <input
            type="text"
            value={line.text}
            onChange={(e) => handleTextChange(index, e.target.value)}
            className="w-full p-3 rounded-lg bg-gray-800 border border-gray-700 focus:border-gray-500 focus:outline-none transition"
            placeholder={getPlaceholder(line)}
          />
          {line.text.length >= CHARACTER_LIMIT && (
            <p className="text-red-500 text-xs mt-1">Maximum character limit reached</p>
          )}
        </div>
      ))}

      <button
        onClick={handleSubmit}
        disabled={isSubmitting || !canSubmit}
        className="my-6 w-full py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 disabled:bg-gray-700 disabled:cursor-not-allowed transition-colors duration-200 font-bold"
      >
        {isSubmitting ? 'Creating...' : 'Create Remix'}
      </button>
    </div>
  );
};

export default BasicRemixEditor;