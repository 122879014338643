import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/EnterTimePortal.css';
import { useUser } from '../contexts/UserContext';
import AppDownloadButton from './AppDownloadButton';
import * as Sentry from '@sentry/react';

const AcceptFriendInvite = () => {
    const navigate = useNavigate();
    const { currentUser, loading: userLoading } = useUser();
    const username = currentUser?.email || currentUser?.uid;
    const [utmSource, setUtmSource] = useState(null);
    const [inviterId, setInviterId] = useState(null);
    const [inviterName, setInviterName] = useState(null);

    useEffect(() => {
        // Extract query parameters
        const queryParams = new URLSearchParams(window.location.search);
        const source = queryParams.get('utm_source');
        const id = queryParams.get('id');
        const name = queryParams.get('name');

        if (source) setUtmSource(source);
        if (id) setInviterId(id);
        if (name) setInviterName(decodeURIComponent(name));

        // Track page view
        trackEvent('friend_invite_page_view', {
            inviter_id: id || 'unknown',
            inviter_name: name ? decodeURIComponent(name) : 'unknown'
        });
    }, []);

    const trackEvent = async (eventName, eventData) => {
        const data = {
            user: username,
            event_name: eventName,
            manufacturer: 'Web',
            ...eventData,
        };

        if (utmSource) {
            data.utm_source = utmSource;
        }

        fetch('https://timetravel-dd209e3dce1f.herokuapp.com/log', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .catch((error) => {
            Sentry.captureException(error);
        });
    };

    const handleExploreTimePortal = () => {
        trackEvent('friend_invite_explore_timeportal', {
            inviter_id: inviterId || 'unknown'
        });
        navigate('/entertimeportal');
    };

    return (
        <div className="min-h-screen bg-black flex flex-col items-center justify-center p-4 overflow-hidden">
            <div className="w-full max-w-4xl mx-auto mb-12 text-center">
                <h1 className="text-white text-4xl md:text-6xl text-center mb-8 font-bold relative z-10 tracking-wider glowing-text cyberpunk-text">
                    TIME PORTAL
                </h1>
                
                <div className="bg-[#001f3f]/50 rounded-lg p-8 backdrop-blur-sm border border-[#4682B4]/30 shadow-[0_0_15px_rgba(70,130,180,0.3)]">
                    <h2 className="text-white text-2xl md:text-3xl mb-6 relative z-10 tracking-wide glowing-text-subtle">
                        {inviterName 
                            ? `${inviterName} invited you to play Time Portal!` 
                            : "You've been invited to play Time Portal!"}
                    </h2>
                    
                    <p className="text-white text-lg md:text-xl mb-8 relative z-10">
                        Download the app to accept this invitation and start your time-traveling adventure together.
                    </p>
                    
                    <div className="flex flex-col items-center gap-6">
                        <div className="w-full max-w-xs">
                            <AppDownloadButton 
                                onDownloadClick={() => trackEvent('friend_invite_download_click', {
                                    inviter_id: inviterId || 'unknown'
                                })}
                            />
                        </div>
                        
                        <div className="w-full border-t border-[#4682B4]/30 pt-6 mt-2">
                            <p className="text-white text-md mb-4">
                                Want to see what Time Portal is about first?
                            </p>
                            <button
                                onClick={handleExploreTimePortal}
                                className="px-6 py-3 text-lg bg-transparent text-white rounded-lg transition-all duration-300 
                                    border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4]
                                    tracking-wider"
                            >
                                EXPLORE TIME PORTAL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AcceptFriendInvite; 