import React, { useState, useEffect } from 'react';
import { doc, getDoc, onSnapshot, updateDoc, setDoc, collection } from 'firebase/firestore';
import { db } from '../Firebase';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

function ReelsCreatorV2() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [reels, setReels] = useState([]);
  const [postStatus, setPostStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [editingReelIndex, setEditingReelIndex] = useState(null);
  const [originalReels, setOriginalReels] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [combinationSlots, setCombinationSlots] = useState({});
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(true);
  const [newReel, setNewReel] = useState({ feed: '', sent: '' });
  const [activeSection, setActiveSection] = useState('create');
  const [expandedReels, setExpandedReels] = useState({});

  // On mount, check authorization
  useEffect(() => {
    const auth = getAuth();
    const allowedEmails = ['smgplank@gmail.com', 'jitsvm@gmail.com'];
    
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user && allowedEmails.includes(user.email)) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
      setLoadingAuth(false);
    });
    
    return () => unsubscribeAuth();
  }, []);

  // Load existing data if ID is provided
  useEffect(() => {
    if (id) {
      const loadExistingData = async () => {
        try {
          setIsLoading(true);
          const docRef = doc(db, 'reels-section', id);
          const docSnap = await getDoc(docRef);
          
          if (docSnap.exists()) {
            setReels(docSnap.data().reels || []);
            setPostStatus('Loaded existing reels!');
          }
        } catch (err) {
          console.error(err);
          setPostStatus('Error loading existing data');
        } finally {
          setIsLoading(false);
        }
      };
      
      loadExistingData();
    }
  }, [id]);

  // Set up real-time listener for updates
  useEffect(() => {
    if (id) {
      const docRef = doc(db, 'reels-section', id);
      
      const unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          // Only update reels if we're not currently editing
          if (editingReelIndex === null) {
            setReels(doc.data().reels || []);
            setPostStatus('Document updated!');
          }
        }
      });
      
      return () => unsubscribe();
    }
  }, [id, editingReelIndex]);

  const handleAddReel = async () => {
    if (!id) {
      // Create a new document if we don't have an ID
      try {
        setIsLoading(true);
        const newDocRef = doc(db, 'reels-section');
        await setDoc(newDocRef, {
          reels: [{ feed: '', sent: '' }]
        });
        navigate(`/reels-creator-v2/${newDocRef.id}`);
        setPostStatus('Created new reels document!');
      } catch (err) {
        console.error(err);
        setPostStatus('Error creating new document');
      } finally {
        setIsLoading(false);
      }
    } else {
      // Add a new reel to the existing document
      try {
        setIsLoading(true);
        const docRef = doc(db, 'reels-section', id);
        const updatedReels = [...reels, { feed: '', sent: '' }];
        await updateDoc(docRef, { reels: updatedReels });
        setReels(updatedReels);
        setPostStatus('Added new reel!');
      } catch (err) {
        console.error(err);
        setPostStatus('Error adding new reel');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCreateReel = async () => {
    if (!newReel.feed || !newReel.sent) {
      setPostStatus('Please fill in both Feed and Sent fields');
      return;
    }

    try {
      setIsLoading(true);
      
      if (!id) {
        // Create a new document if we don't have an ID
        const newDocRef = doc(collection(db, 'reels-section'));
        await setDoc(newDocRef, {
          reels: [newReel]
        });
        navigate(`/reels-creator-v2/${newDocRef.id}`);
        setPostStatus('Created new reels document!');
      } else {
        // Add the new reel to the existing document
        const docRef = doc(db, 'reels-section', id);
        const updatedReels = [...reels, newReel];
        await updateDoc(docRef, { reels: updatedReels });
        setReels(updatedReels);
        setPostStatus('Added new reel!');
      }
      
      // Reset the new reel form
      setNewReel({ feed: '', sent: '' });
    } catch (err) {
      console.error(err);
      setPostStatus('Error creating new reel');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGeneratePrompts = async (reelIndex) => {
    const reel = reels[reelIndex];
    
    if (!reel.feed || !reel.sent) {
      setPostStatus('Please fill in both Feed and Sent fields');
      return;
    }

    try {
      setIsLoading(true);
      setPostStatus('Generating POV prompts...');

      const response = await fetch('http://127.0.0.1:2999/generate-pov-prompts', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          id,
          reelIndex,
          feed: reel.feed,
          sent: reel.sent
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate prompts.');
      }

      setPostStatus('Prompts generation requested. Waiting for results...');
    } catch (err) {
      console.error(err);
      setPostStatus(err.message || 'Error generating prompts.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleReelEdit = (reelIndex, field, value) => {
    const updatedReels = reels.map((reel, idx) => {
      if (idx === reelIndex) {
        return { ...reel, [field]: value };
      }
      return reel;
    });
    setReels(updatedReels);
  };

  const handleEditClick = (idx) => {
    if (editingReelIndex === idx) {
      // Clicking "Done" - check if changes were made
      const originalReel = originalReels[idx];
      const currentReel = reels[idx];
      
      if (originalReel.feed !== currentReel.feed || originalReel.sent !== currentReel.sent) {
        saveReelChanges();
      }
      setOriginalReels(null);
      setEditingReelIndex(null);
    } else {
      // Starting to edit - store original values
      setOriginalReels([...reels]);
      setEditingReelIndex(idx);
    }
  };

  const saveReelChanges = async () => {
    try {
      const docRef = doc(db, 'reels-section', id);
      await updateDoc(docRef, {
        reels: reels
      });
      setPostStatus('Changes saved successfully!');
    } catch (error) {
      console.error('Error saving changes:', error);
      setPostStatus('Error saving changes');
    }
  };

  const handleGenerateImages = async (reelIndex, isForFeed) => {
    const reel = reels[reelIndex];
    const promptField = isForFeed ? 'feed_pov_prompts' : 'sent_pov_prompts';
    
    if (!reel[promptField]) {
      setPostStatus(`Please generate prompts first or wait for them to load`);
      return;
    }

    try {
      setIsLoading(true);
      setPostStatus(`Generating ${isForFeed ? 'feed' : 'sent'} images...`);

      const response = await fetch('http://127.0.0.1:2999/generate-pov-images', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          id,
          reelIndex,
          isForFeed,
          prompt: reel[promptField]
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate images.');
      }

      setPostStatus(`${isForFeed ? 'Feed' : 'Sent'} images generation requested. Waiting for results...`);
    } catch (err) {
      console.error(err);
      setPostStatus(err.message || 'Error generating images.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAnimateImage = async (reelIndex, imageUrl, isForFeed, customPrompt = '') => {
    try {
      setIsLoading(true);
      setPostStatus(`Animating image...`);

      const response = await fetch('http://127.0.0.1:2999/generate-video', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          id,
          jokeIndex: reelIndex,
          image_url: imageUrl,
          isOnFeed: isForFeed,
          arrayName: 'reels',
          prompt: customPrompt || undefined  // Only include if there's a value
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to animate image.');
      }

      setPostStatus(`Animation requested for image`);
    } catch (err) {
      console.error(err);
      setPostStatus(err.message || 'Error animating image.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCombineRequestVideos = async (reelIndex) => {
    try {
      setIsLoading(true);
      setPostStatus(`Combining videos...`);

      const reel = reels[reelIndex];
      const slots = combinationSlots[`reel_${reelIndex}`] || {};
      
      // Get all top and bottom videos
      const topVideos = Object.keys(slots)
        .filter(key => key.startsWith('top_'))
        .sort()
        .map(key => slots[key])
        .filter(Boolean);
        
      const bottomVideos = Object.keys(slots)
        .filter(key => key.startsWith('bottom_'))
        .sort()
        .map(key => slots[key])
        .filter(Boolean);

      if (topVideos.length === 0 || bottomVideos.length === 0) {
        throw new Error('Please select at least one video for both top and bottom');
      }

      const response = await fetch('http://127.0.0.1:2999/combine-request-videos', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id,
          jokeIndex: reelIndex,
          topVideos: topVideos,
          bottomVideos: bottomVideos,
          feedText: reel.feed,
          sentText: reel.sent,
          arrayName: 'reels'
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to combine videos.');
      }

      setPostStatus(`Videos combination requested`);
    } catch (err) {
      console.error(err);
      setPostStatus(err.message || 'Error combining videos.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSlotDrop = (reelIndex, slot, slotIndex, videoUrl) => {
    setCombinationSlots(prev => {
      const slotKey = `reel_${reelIndex}`;
      const slotId = `${slot}_${slotIndex}`;
      
      return {
        ...prev,
        [slotKey]: {
          ...(prev[slotKey] || {}),
          [slotId]: videoUrl
        }
      };
    });
  };

  const addVideoSlot = (reelIndex) => {
    setCombinationSlots(prev => {
      const slotKey = `reel_${reelIndex}`;
      const currentSlots = prev[slotKey] || {};
      
      // Count existing top and bottom slots
      const topSlotCount = Object.keys(currentSlots).filter(k => k.startsWith('top_')).length || 0;
      const bottomSlotCount = Object.keys(currentSlots).filter(k => k.startsWith('bottom_')).length || 0;
      
      // Add one more slot for each
      return {
        ...prev,
        [slotKey]: {
          ...currentSlots,
          [`top_${topSlotCount}`]: currentSlots[`top_${topSlotCount}`] || null,
          [`bottom_${bottomSlotCount}`]: currentSlots[`bottom_${bottomSlotCount}`] || null
        }
      };
    });
  };

  const handleGenerateImageForPrompt = async (reelIndex, isForFeed, promptIndex) => {
    const reel = reels[reelIndex];
    const promptField = isForFeed ? 'feed_pov_prompts' : 'sent_pov_prompts';
    
    if (!reel[promptField] || !reel[promptField][promptIndex]) {
      setPostStatus(`Please provide a prompt first`);
      return;
    }

    try {
      setIsLoading(true);
      setPostStatus(`Generating ${isForFeed ? 'feed' : 'sent'} image for prompt #${promptIndex + 1}...`);

      const response = await fetch('http://127.0.0.1:2999/generate-pov-image-for-prompt', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          id,
          reelIndex,
          isForFeed,
          promptIndex,
          prompt: reel[promptField][promptIndex]
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate image.');
      }

      setPostStatus(`Image generation requested for ${isForFeed ? 'feed' : 'sent'} prompt #${promptIndex + 1}. Waiting for results...`);
    } catch (err) {
      console.error(err);
      setPostStatus(err.message || 'Error generating image.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateAllImages = async (reelIndex, isForFeed) => {
    const reel = reels[reelIndex];
    const promptField = isForFeed ? 'feed_pov_prompts' : 'sent_pov_prompts';
    
    if (!reel[promptField] || !Array.isArray(reel[promptField]) || reel[promptField].length === 0) {
      setPostStatus(`No ${isForFeed ? 'feed' : 'sent'} prompts available to generate images`);
      return;
    }

    try {
      setIsLoading(true);
      setPostStatus(`Generating all ${isForFeed ? 'feed' : 'sent'} images...`);

      // Send individual requests for each prompt
      const promises = reel[promptField].map((prompt, promptIndex) => 
        fetch('http://127.0.0.1:2999/generate-pov-image-for-prompt', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            id,
            reelIndex,
            isForFeed,
            promptIndex,
            prompt
          }),
        })
      );

      // Wait for all requests to be sent (not necessarily completed)
      await Promise.all(promises);

      setPostStatus(`All ${isForFeed ? 'feed' : 'sent'} image generation requests sent. Waiting for results...`);
    } catch (err) {
      console.error(err);
      setPostStatus(err.message || 'Error generating images.');
    } finally {
      setIsLoading(false);
    }
  };

  const ImageModal = ({ imageUrl, onClose }) => {
    if (!imageUrl) return null;
    
    // Check if it's a video URL by looking for video-specific patterns
    const isVideo = imageUrl.includes('/video/') || imageUrl.match(/\.(mp4|webm|ogg)$/i);
    
    return (
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
        onClick={onClose}
      >
        <div className="max-w-4xl max-h-[90vh] relative">
          {isVideo ? (
            <video 
              src={imageUrl} 
              className="max-h-[90vh] object-contain"
              controls
              autoPlay
              onClick={e => e.stopPropagation()}
              style={{ width: 'auto', height: '90vh' }}
            />
          ) : (
            <img 
              src={imageUrl} 
              alt="Full size" 
              className="max-w-full max-h-[90vh] object-contain"
              onClick={e => e.stopPropagation()}
            />
          )}
          <button
            className="absolute top-4 right-4 bg-white rounded-full p-2 text-black hover:bg-gray-200"
            onClick={onClose}
          >
            ✕
          </button>
        </div>
      </div>
    );
  };

  // Function to toggle expanded state for a reel in the sidebar
  const toggleReelExpand = (idx) => {
    setExpandedReels(prev => ({
      ...prev,
      [idx]: !prev[idx]
    }));
  };

  // Generate section IDs for each reel and subsections
  const getReelSectionId = (idx) => `reel-${idx}`;
  const getSubsectionId = (reelIdx, section) => `reel-${reelIdx}-${section}`;

  // Function to scroll to a section or subsection
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setActiveSection(sectionId);
    }
  };

  if (loadingAuth) {
    return <div className="text-center p-8">Checking authorization...</div>;
  }

  if (!isAuthorized) {
    return (
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-6">Access Denied</h1>
        <p>You do not have permission to view this content.</p>
      </div>
    );
  }

  return (
    <div className="flex">
      {/* Sidebar Navigation */}
      <div className="w-64 h-screen bg-gray-100 p-4 fixed left-0 top-0 overflow-y-auto">
        <h2 className="text-xl font-semibold mb-4">Navigation</h2>
        <ul className="space-y-2">
          <li>
            <button 
              className={`w-full text-left p-2 rounded ${activeSection === 'create' ? 'bg-blue-500 text-white' : 'hover:bg-gray-200'}`}
              onClick={() => scrollToSection('create')}
            >
              Create New Reel
            </button>
          </li>
          
          {reels.map((reel, idx) => (
            <li key={idx} className="space-y-1">
              <div className="flex items-center">
                <button 
                  className={`flex-grow text-left p-2 rounded ${activeSection === getReelSectionId(idx) ? 'bg-blue-500 text-white' : 'hover:bg-gray-200'}`}
                  onClick={() => scrollToSection(getReelSectionId(idx))}
                >
                  Reel #{idx + 1}
                </button>
                <button 
                  className="p-1 ml-1 text-gray-600 hover:bg-gray-200 rounded"
                  onClick={() => toggleReelExpand(idx)}
                >
                  {expandedReels[idx] ? '▼' : '►'}
                </button>
              </div>
              
              {expandedReels[idx] && (
                <ul className="pl-4 space-y-1">
                  <li>
                    <button 
                      className={`w-full text-left p-1 text-sm rounded ${activeSection === getSubsectionId(idx, 'prompts') ? 'bg-blue-400 text-white' : 'hover:bg-gray-200'}`}
                      onClick={() => scrollToSection(getSubsectionId(idx, 'prompts'))}
                    >
                      Prompts
                    </button>
                  </li>
                  <li>
                    <button 
                      className={`w-full text-left p-1 text-sm rounded ${activeSection === getSubsectionId(idx, 'feed-images') ? 'bg-blue-400 text-white' : 'hover:bg-gray-200'}`}
                      onClick={() => scrollToSection(getSubsectionId(idx, 'feed-images'))}
                    >
                      Feed Images
                    </button>
                  </li>
                  <li>
                    <button 
                      className={`w-full text-left p-1 text-sm rounded ${activeSection === getSubsectionId(idx, 'feed-videos') ? 'bg-blue-400 text-white' : 'hover:bg-gray-200'}`}
                      onClick={() => scrollToSection(getSubsectionId(idx, 'feed-videos'))}
                    >
                      Feed Videos
                    </button>
                  </li>
                  <li>
                    <button 
                      className={`w-full text-left p-1 text-sm rounded ${activeSection === getSubsectionId(idx, 'sent-images') ? 'bg-blue-400 text-white' : 'hover:bg-gray-200'}`}
                      onClick={() => scrollToSection(getSubsectionId(idx, 'sent-images'))}
                    >
                      Sent Images
                    </button>
                  </li>
                  <li>
                    <button 
                      className={`w-full text-left p-1 text-sm rounded ${activeSection === getSubsectionId(idx, 'sent-videos') ? 'bg-blue-400 text-white' : 'hover:bg-gray-200'}`}
                      onClick={() => scrollToSection(getSubsectionId(idx, 'sent-videos'))}
                    >
                      Sent Videos
                    </button>
                  </li>
                  <li>
                    <button 
                      className={`w-full text-left p-1 text-sm rounded ${activeSection === getSubsectionId(idx, 'combine') ? 'bg-blue-400 text-white' : 'hover:bg-gray-200'}`}
                      onClick={() => scrollToSection(getSubsectionId(idx, 'combine'))}
                    >
                      Combine Videos
                    </button>
                  </li>
                  <li>
                    <button 
                      className={`w-full text-left p-1 text-sm rounded ${activeSection === getSubsectionId(idx, 'combined') ? 'bg-blue-400 text-white' : 'hover:bg-gray-200'}`}
                      onClick={() => scrollToSection(getSubsectionId(idx, 'combined'))}
                    >
                      Combined Videos
                    </button>
                  </li>
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Main Content */}
      <div className="ml-64 p-4 max-w-4xl w-full space-y-4">
        <h1 className="text-2xl font-semibold">Instagram Reels Creator V2</h1>
        
        {/* Add new reel section */}
        <div id="create" className="border rounded p-4 space-y-3">
          <h2 className="font-semibold">Create New Reel</h2>
          <div>
            <label className="block text-sm text-gray-600">On your feed: </label>
            <input
              type="text"
              className="w-full p-2 border rounded"
              value={newReel.feed}
              onChange={(e) => setNewReel({...newReel, feed: e.target.value})}
              placeholder="Enter feed text"
            />
          </div>
          <div>
            <label className="block text-sm text-gray-600">Sent to you: </label>
            <input
              type="text"
              className="w-full p-2 border rounded"
              value={newReel.sent}
              onChange={(e) => setNewReel({...newReel, sent: e.target.value})}
              placeholder="Enter sent text"
            />
          </div>
          <button
            className="w-full px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
            onClick={handleCreateReel}
            disabled={!newReel.feed || !newReel.sent || isLoading}
          >
            Create Reel
          </button>
        </div>

        {/* Status / Loading */}
        <div className="text-gray-700">
          <p>{postStatus}</p>
        </div>

        {/* Existing reels */}
        {reels.length > 0 && (
          <div className="space-y-4">
            <h2 className="font-semibold">Your Reels</h2>
            {reels.map((reel, idx) => (
              <div id={getReelSectionId(idx)} key={idx} className="border rounded p-4 space-y-3">
                <div className="flex items-center justify-between mb-2">
                  <h3 className="font-medium">Reel #{idx + 1}</h3>
                  <button
                    className="text-blue-500 text-sm"
                    onClick={() => handleEditClick(idx)}
                  >
                    {editingReelIndex === idx ? 'Done' : 'Edit'}
                  </button>
                </div>
                
                {/* Feed and Sent text */}
                {editingReelIndex === idx ? (
                  <div className="space-y-2">
                    <div>
                      <label className="block text-sm text-gray-600">If this is on your feed, </label>
                      <input
                        type="text"
                        className="w-full p-2 border rounded"
                        value={reel.feed || ''}
                        onChange={(e) => handleReelEdit(idx, 'feed', e.target.value)}
                      />
                    </div>
                    <div>
                      <label className="block text-sm text-gray-600">If this was sent to you, </label>
                      <input
                        type="text"
                        className="w-full p-2 border rounded"
                        value={reel.sent || ''}
                        onChange={(e) => handleReelEdit(idx, 'sent', e.target.value)}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>If this is on your feed, {reel.feed}</div>
                    <div>If this was sent to you, {reel.sent}</div>
                  </div>
                )}
                
                {/* Generate Prompts button */}
                <button
                  className="w-full px-4 py-2 bg-purple-500 text-white rounded disabled:opacity-50"
                  onClick={() => handleGeneratePrompts(idx)}
                  disabled={isLoading}
                >
                  Generate Prompts
                </button>
                
                {/* Prompts section */}
                {(reel.feed_pov_prompts || reel.sent_pov_prompts) && (
                  <div id={getSubsectionId(idx, 'prompts')} className="space-y-3 mt-3 p-3 bg-gray-50 rounded">
                    <h4 className="font-medium">Prompts</h4>
                    
                    {/* Feed prompts */}
                    {reel.feed_pov_prompts && (
                      <div className="space-y-2">
                        <div className="flex justify-between items-center">
                          <label className="block text-sm text-gray-600 font-medium">Feed Prompts:</label>
                          {Array.isArray(reel.feed_pov_prompts) && reel.feed_pov_prompts.length > 0 && (
                            <button
                              className="px-2 py-1 bg-green-500 text-white rounded text-sm"
                              onClick={() => handleGenerateAllImages(idx, true)}
                              disabled={isLoading}
                            >
                              Generate All
                            </button>
                          )}
                        </div>
                        {Array.isArray(reel.feed_pov_prompts) ? (
                          <div className="space-y-3">
                            {reel.feed_pov_prompts.map((prompt, promptIdx) => (
                              <div key={promptIdx} className="flex space-x-2">
                                <textarea
                                  className="w-full p-2 border rounded min-h-[100px]"
                                  value={prompt}
                                  onChange={(e) => {
                                    const updatedPrompts = [...reel.feed_pov_prompts];
                                    updatedPrompts[promptIdx] = e.target.value;
                                    handleReelEdit(idx, 'feed_pov_prompts', updatedPrompts);
                                  }}
                                  style={{ height: `${Math.max(100, prompt.split('\n').length * 20)}px` }}
                                />
                                <button
                                  className="px-2 py-1 bg-blue-500 text-white rounded text-sm whitespace-nowrap h-fit"
                                  onClick={() => handleGenerateImageForPrompt(idx, true, promptIdx)}
                                  disabled={isLoading}
                                >
                                  Generate
                                </button>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <textarea
                            className="w-full p-2 border rounded min-h-[100px]"
                            value={reel.feed_pov_prompts}
                            onChange={(e) => handleReelEdit(idx, 'feed_pov_prompts', e.target.value)}
                          />
                        )}
                      </div>
                    )}
                    
                    {/* Sent prompts */}
                    {reel.sent_pov_prompts && (
                      <div className="space-y-2 mt-4">
                        <div className="flex justify-between items-center">
                          <label className="block text-sm text-gray-600 font-medium">Sent Prompts:</label>
                          {Array.isArray(reel.sent_pov_prompts) && reel.sent_pov_prompts.length > 0 && (
                            <button
                              className="px-2 py-1 bg-green-500 text-white rounded text-sm"
                              onClick={() => handleGenerateAllImages(idx, false)}
                              disabled={isLoading}
                            >
                              Generate All
                            </button>
                          )}
                        </div>
                        {Array.isArray(reel.sent_pov_prompts) ? (
                          <div className="space-y-3">
                            {reel.sent_pov_prompts.map((prompt, promptIdx) => (
                              <div key={promptIdx} className="flex space-x-2">
                                <textarea
                                  className="w-full p-2 border rounded min-h-[100px]"
                                  value={prompt}
                                  onChange={(e) => {
                                    const updatedPrompts = [...reel.sent_pov_prompts];
                                    updatedPrompts[promptIdx] = e.target.value;
                                    handleReelEdit(idx, 'sent_pov_prompts', updatedPrompts);
                                  }}
                                  style={{ height: `${Math.max(100, prompt.split('\n').length * 20)}px` }}
                                />
                                <button
                                  className="px-2 py-1 bg-blue-500 text-white rounded text-sm whitespace-nowrap h-fit"
                                  onClick={() => handleGenerateImageForPrompt(idx, false, promptIdx)}
                                  disabled={isLoading}
                                >
                                  Generate
                                </button>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <textarea
                            className="w-full p-2 border rounded min-h-[100px]"
                            value={reel.sent_pov_prompts}
                            onChange={(e) => handleReelEdit(idx, 'sent_pov_prompts', e.target.value)}
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}
                
                {/* Images and Videos section */}
                {(reel.feed_image_urls?.length > 0 || reel.sent_image_urls?.length > 0 || 
                  reel.feed_video_urls?.length > 0 || reel.sent_video_urls?.length > 0) && (
                  <div className="mt-4 space-y-4">
                    {/* Feed Images */}
                    {reel.feed_image_urls?.length > 0 && (
                      <div id={getSubsectionId(idx, 'feed-images')}>
                        <p className="font-medium mb-1">Feed Images:</p>
                        <div className="flex flex-wrap gap-2">
                          {[...reel.feed_image_urls].reverse().map((imgUrl, iidx) => (
                            <div
                              key={iidx}
                              className="border cursor-pointer relative w-[calc(50%-4px)] flex flex-col"
                            >
                              <img 
                                src={imgUrl} 
                                alt="feed image" 
                                className="w-full aspect-square object-cover cursor-pointer hover:opacity-80"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedImage(imgUrl);
                                  setShowModal(true);
                                }}
                              />
                              <div className="w-full p-1 flex flex-col space-y-1">
                                <input
                                  type="text"
                                  placeholder="Custom prompt (optional)"
                                  className="w-full p-1 text-sm rounded border"
                                  onClick={(e) => e.stopPropagation()}
                                  id={`feed-prompt-${idx}-${iidx}`}
                                />
                                <button
                                  className="w-full bg-blue-500 text-white text-sm py-1 rounded hover:bg-blue-600"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const customPrompt = document.getElementById(`feed-prompt-${idx}-${iidx}`).value;
                                    handleAnimateImage(idx, imgUrl, true, customPrompt);
                                  }}
                                >
                                  Animate
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {/* Feed Videos */}
                    {reel.feed_video_urls?.length > 0 && (
                      <div id={getSubsectionId(idx, 'feed-videos')}>
                        <p className="font-medium mb-1">Feed Videos:</p>
                        <div className="flex flex-wrap gap-2">
                          {[...reel.feed_video_urls].reverse().map((videoUrl, vidx) => (
                            <div
                              key={vidx}
                              className="border relative w-[calc(50%-4px)]"
                            >
                              <video 
                                src={videoUrl} 
                                className="w-full aspect-square object-cover cursor-pointer hover:opacity-80"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedImage(videoUrl);
                                  setShowModal(true);
                                }}
                                controls
                                draggable="true"
                                onDragStart={(e) => {
                                  e.dataTransfer.setData('text/plain', videoUrl);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {/* Sent Images */}
                    {reel.sent_image_urls?.length > 0 && (
                      <div id={getSubsectionId(idx, 'sent-images')}>
                        <p className="font-medium mb-1">Sent Images:</p>
                        <div className="flex flex-wrap gap-2">
                          {[...reel.sent_image_urls].reverse().map((imgUrl, iidx) => (
                            <div
                              key={iidx}
                              className="border cursor-pointer relative w-[calc(50%-4px)] flex flex-col"
                            >
                              <img 
                                src={imgUrl} 
                                alt="sent image" 
                                className="w-full aspect-square object-cover cursor-pointer hover:opacity-80"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedImage(imgUrl);
                                  setShowModal(true);
                                }}
                              />
                              <div className="w-full p-1 flex flex-col space-y-1">
                                <input
                                  type="text"
                                  placeholder="Custom prompt (optional)"
                                  className="w-full p-1 text-sm rounded border"
                                  onClick={(e) => e.stopPropagation()}
                                  id={`sent-prompt-${idx}-${iidx}`}
                                />
                                <button
                                  className="w-full bg-blue-500 text-white text-sm py-1 rounded hover:bg-blue-600"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const customPrompt = document.getElementById(`sent-prompt-${idx}-${iidx}`).value;
                                    handleAnimateImage(idx, imgUrl, false, customPrompt);
                                  }}
                                >
                                  Animate
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {/* Sent Videos */}
                    {reel.sent_video_urls?.length > 0 && (
                      <div id={getSubsectionId(idx, 'sent-videos')}>
                        <p className="font-medium mb-1">Sent Videos:</p>
                        <div className="flex flex-wrap gap-2">
                          {[...reel.sent_video_urls].reverse().map((videoUrl, vidx) => (
                            <div
                              key={vidx}
                              className="border relative w-[calc(50%-4px)]"
                            >
                              <video 
                                src={videoUrl} 
                                className="w-full aspect-square object-cover cursor-pointer hover:opacity-80"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedImage(videoUrl);
                                  setShowModal(true);
                                }}
                                controls
                                draggable="true"
                                onDragStart={(e) => {
                                  e.dataTransfer.setData('text/plain', videoUrl);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {/* Video Combination Section */}
                {(reel.feed_video_urls?.length > 0 && reel.sent_video_urls?.length > 0) && (
                  <div id={getSubsectionId(idx, 'combine')} className="mt-4">
                    <p className="font-medium mb-1">Combine Videos:</p>
                    <div className="space-y-2 border rounded p-2">
                      {/* Top video slots */}
                      <p className="text-sm font-medium">Top (Feed) Videos:</p>
                      <div className="flex overflow-x-auto pb-2 space-x-2">
                        {Array.from({ length: Object.keys(combinationSlots[`reel_${idx}`] || {})
                          .filter(k => k.startsWith('top_')).length || 1 }).map((_, slotIdx) => (
                          <div 
                            key={`top_${slotIdx}`}
                            className="border-2 border-dashed p-2 min-w-[150px] w-[150px] aspect-square flex-shrink-0 flex items-center justify-center"
                            onDragOver={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                            onDrop={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              const url = e.dataTransfer.getData('text/plain');
                              if (url.includes('feed_video') || url.includes('.mp4')) {
                                handleSlotDrop(idx, 'top', slotIdx, url);
                              }
                            }}
                          >
                            {combinationSlots[`reel_${idx}`]?.[`top_${slotIdx}`] ? (
                              <div className="relative w-full h-full">
                                <video 
                                  src={combinationSlots[`reel_${idx}`][`top_${slotIdx}`]} 
                                  className="w-full h-full object-cover" 
                                  controls
                                />
                                <button 
                                  className="absolute top-0 right-0 bg-red-500 text-white p-1"
                                  onClick={() => handleSlotDrop(idx, 'top', slotIdx, null)}
                                >
                                  ×
                                </button>
                              </div>
                            ) : (
                              <p className="text-gray-500 text-xs text-center">Drag feed video here</p>
                            )}
                          </div>
                        ))}
                        <button 
                          className="min-w-[40px] h-[150px] border-2 border-dashed flex items-center justify-center text-2xl text-gray-500"
                          onClick={() => addVideoSlot(idx)}
                        >
                          +
                        </button>
                      </div>
                      
                      {/* Bottom video slots */}
                      <p className="text-sm font-medium">Bottom (Sent) Videos:</p>
                      <div className="flex overflow-x-auto pb-2 space-x-2">
                        {Array.from({ length: Object.keys(combinationSlots[`reel_${idx}`] || {})
                          .filter(k => k.startsWith('bottom_')).length || 1 }).map((_, slotIdx) => (
                          <div 
                            key={`bottom_${slotIdx}`}
                            className="border-2                           border-dashed p-2 min-w-[150px] w-[150px] aspect-square flex-shrink-0 flex items-center justify-center"
                            onDragOver={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                            onDrop={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              const url = e.dataTransfer.getData('text/plain');
                              if (url.includes('sent_video') || url.includes('.mp4')) {
                                handleSlotDrop(idx, 'bottom', slotIdx, url);
                              }
                            }}
                          >
                            {combinationSlots[`reel_${idx}`]?.[`bottom_${slotIdx}`] ? (
                              <div className="relative w-full h-full">
                                <video 
                                  src={combinationSlots[`reel_${idx}`][`bottom_${slotIdx}`]} 
                                  className="w-full h-full object-cover" 
                                  controls
                                />
                                <button 
                                  className="absolute top-0 right-0 bg-red-500 text-white p-1"
                                  onClick={() => handleSlotDrop(idx, 'bottom', slotIdx, null)}
                                >
                                  ×
                                </button>
                              </div>
                            ) : (
                              <p className="text-gray-500 text-xs text-center">Drag sent video here</p>
                            )}
                          </div>
                        ))}
                        <button 
                          className="min-w-[40px] h-[150px] border-2 border-dashed flex items-center justify-center text-2xl text-gray-500"
                          onClick={() => addVideoSlot(idx)}
                        >
                          +
                        </button>
                      </div>
                      
                      <button
                        className="w-full px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
                        disabled={
                          !Object.keys(combinationSlots[`reel_${idx}`] || {}).some(k => k.startsWith('top_') && combinationSlots[`reel_${idx}`][k]) || 
                          !Object.keys(combinationSlots[`reel_${idx}`] || {}).some(k => k.startsWith('bottom_') && combinationSlots[`reel_${idx}`][k]) || 
                          isLoading
                        }
                        onClick={() => handleCombineRequestVideos(idx)}
                      >
                        Combine Videos
                      </button>
                    </div>
                  </div>
                )}

                {/* Separate Combined Videos section */}
                {reel.combined_video_urls?.length > 0 && (
                  <div id={getSubsectionId(idx, 'combined')} className="mt-4">
                    <p className="font-medium mb-1">Combined Videos:</p>
                    <div className="flex flex-wrap gap-2">
                      {[...reel.combined_video_urls].reverse().map((videoUrl, vidx) => (
                        <div
                          key={vidx}
                          className="border relative w-[calc(50%-4px)]"
                        >
                          <video 
                            src={videoUrl} 
                            className="w-full aspect-[9/16] object-cover cursor-pointer hover:opacity-80"
                            onClick={() => {
                              setSelectedImage(videoUrl);
                              setShowModal(true);
                            }}
                            controls
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {/* Status / Loading (bottom) */}
        <div className="text-gray-700">
          <p>{postStatus}</p>
        </div>

        {showModal && <ImageModal imageUrl={selectedImage} onClose={() => {
          setShowModal(false);
          setSelectedImage(null);
        }} />}
      </div>
    </div>
  );
}

export default ReelsCreatorV2;
