import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useParams } from 'react-router-dom';

import { useUser } from '../contexts/UserContext';
import { db, app, onUploadPlaygroundFinetunePhotos, launchApp, updateSeenIntro } from '../Firebase.js' 
import { doc, onSnapshot, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import MenuBar from '../MenuBar'
import ImageModal from '../Components/ImageModal.jsx'
import IntroModal from '../Components/IntroModal.jsx'
import ResultsGrid from '../Components/ResultsGrid.jsx'

import AssetViewer from './AssetViewer.jsx'
import AudioClips from './AudioClips.jsx'
import VideoComposer from './VideoComposer'
import CompletedVideos from "./CompletedVideos"
import DiscontinuedMessageScreen from '../Components/DiscontinuedMessageScreen.jsx'

function ComposeVideo() {
  const { specialState } = useParams();
	const {currentUser, loading} = useUser();
  const username = currentUser?.email || currentUser?.uid;
  const [playgroundState, setPlaygroundState] = useState({
    finetune_predictions_video: [],
    finetune_predictions_audio: []
  });
  const [imageModalOpen, setImageModalOpen] = useState(false)
  const [imagesForModal, setImagesForModal] = useState(false)
  const [clips, setClips] = useState([]);
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const [completedVideos, setCompletedVideos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [videoClips, setVideoClips] = useState([]);
  const [audioClips, setAudioClips] = useState([]);
  const combinedVideosRef = useRef(null);

  const totalVideoDuration = useMemo(() => {
    return videoClips.reduce((acc, clip) => acc + ((clip.endTrim - clip.startTrim) / clip.speed), 0);
  }, [videoClips]);


  const navigate = useNavigate();

  const navigateBack = () => {
    // Check if there is a specialState and navigate accordingly
    const path = specialState ? `/playground/${specialState}` : '/playground';
    navigate(path);
  };

  const updateAssets = async (newAssetUrl) => {
    // Step 1: Fetch all video prediction documents
    const predictionsRef = collection(db, "character-users", username, "finetune_predictions");
    const q = query(predictionsRef, where("asset_type", "==", "VIDEO"));
    const querySnapshot = await getDocs(q);
  
    // Step 2: Iterate over each document in the snapshot
    querySnapshot.forEach(async (doc) => {
      // Each doc represents a video prediction
      const prediction = doc.data();
  
      // Ensure output_images is an array and prepend the newAssetUrl
      const newOutputImages = Array.isArray(prediction.output_images) ? [newAssetUrl, ...prediction.output_images] : [newAssetUrl];
  
      // Step 3: Update the document with the new output images array
      await updateDoc(doc.ref, {
        output_images: newOutputImages
      });
    });
  };

  useEffect(() => {
    if (!username) return;
  
    const predictionsRef = collection(db, "character-users", username, "finetune_predictions");
    const qVideo = query(predictionsRef, where("asset_type", "==", "VIDEO"));
    const qAudio = query(predictionsRef, where("asset_type", "==", "AUDIO"));
  
    const unsubscribeVideo = onSnapshot(qVideo, (querySnapshot) => {
      const videoPredictions = [];
      querySnapshot.forEach((doc) => {
        videoPredictions.push({ id: doc.id, ...doc.data() });
      });
      setPlaygroundState(prevState => ({
        ...prevState,
        finetune_predictions_video: videoPredictions
      }));
    });
  
    const unsubscribeAudio = onSnapshot(qAudio, (querySnapshot) => {
      const audioPredictions = [];
      querySnapshot.forEach((doc) => {
        audioPredictions.push({ id: doc.id, ...doc.data() });
      });
      // Reverse the array before setting the state
      const reversedAudio = audioPredictions.reverse();
      setPlaygroundState(prevState => ({
        ...prevState,
        finetune_predictions_audio: reversedAudio
      }));
    });
  
    return () => {
      unsubscribeVideo();
      unsubscribeAudio();
    };
  }, [username]);
  
  useEffect(() => {
    if (username == null) return;
    const docRef = doc(db, "character-users", username);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setCompletedVideos(data.combined_videos || []); // Update this line
        setUploadedVideos(data.uploaded_videos || []);
      } else {
        console.log("No such document!");
      }
    });
  
    return () => unsubscribe();
  }, [username]);

  const seeImages = (images) => {
    setImagesForModal(images)
    setImageModalOpen(true)

  }
  const onImageModalClose = () => {
    setImagesForModal(null)
    setImageModalOpen(false)
  }

  const addToComposer = (assetUrl) => {
    const video = document.createElement('video');
    video.src = assetUrl;
    video.onloadedmetadata = () => {
      const duration = video.duration;
      // If it's the first video clip being added, set startTrim to 0

      const newClip = {
        url: assetUrl,
        isSelected: false,
        startTrim: 0, // Start at 0 for the first clip, or append to the last clip
        endTrim: duration,
        speed: 1,
        duration: duration,
      };
  
      setVideoClips(prevClips => [...prevClips, newClip]);
    };
  };  
  
  if (loading) return <div>Loading...</div>;  
  if (currentUser == null) {
    const loginPath = specialState ? `/login/${specialState}` : '/login';
    navigate(loginPath);
  }
  if (playgroundState == null) return <div>Loading...</div>;

  const onSelectClip = (index) => {
    setClips(clips.map((clip, i) => ({
      ...clip,
      isSelected: i === index ? !clip.isSelected : clip.isSelected
    })));
  };

  const onDeleteClip = (index, event) => {
    event.stopPropagation(); // Prevent onSelectClip from firing
    setClips(clips.filter((_, i) => i !== index));
  };

  const onDragStart = (event, asset) => {
    event.dataTransfer.setData('text/plain', asset);
  };

  const onDragOver = (event) => {
    event.preventDefault(); // Necessary to allow the drop
  };

  const onDrop = (event) => {
    event.preventDefault();
    const assetUrl = event.dataTransfer.getData('text/plain');
    const newClip = {
      url: assetUrl,
      isSelected: false,
      length: 5
    };
    setClips([...clips, newClip]);
  };

  const addAudioToComposer = (assetUrl, duration) => {
    if (duration > totalVideoDuration) {
        return; // Early return if the clip is longer than the entire video
    }

    // Sort clips by offset to find gaps
    const sortedClips = [...audioClips].sort((a, b) => a.offset - b.offset);
    let potentialStart = 0;

    for (let i = 0; i < sortedClips.length; i++) {
        const currentClip = sortedClips[i];
        const nextStart = currentClip.offset + currentClip.duration;

        // Check if there is a gap between the current clip and the next possible start position
        if (potentialStart + duration <= currentClip.offset) {
            break; // Found a gap large enough for the new clip
        }

        // Update potential start to the end of the current clip if no gap is found
        potentialStart = nextStart;
    }

    // Check if the potential start is within the video duration
    if (potentialStart + duration > totalVideoDuration) {
        return; // No suitable gap found
    }

    // Prepare the new clip with the calculated start position
    const newClip = {
        url: assetUrl,
        isSelected: false,
        startTrim: potentialStart,
        endTrim: potentialStart + duration,
        speed: 1,
        duration: duration,
        offset: potentialStart
    };

    // Add the new clip to the list if it doesn't overlap
    if (!doesOverlap(newClip)) {
        setAudioClips(prevAudioClips => [...prevAudioClips, newClip]);
    }
  };


  const doesOverlap = (newClip, ignoreIndex = null) => {
    // Check if the new clip goes beyond the total video duration
    if (newClip.offset + newClip.duration > totalVideoDuration) {
      return true;
    }

    // Check overlap with other clips
    return audioClips.some((clip, index) => {
      if (index === ignoreIndex) return false; // Skip the clip itself if it's being moved

      const endNew = newClip.offset + newClip.duration;
      const endCurrent = clip.offset + clip.duration;
      return (newClip.offset < endCurrent && endNew > clip.offset);
    });
  };

  return (
    <DiscontinuedMessageScreen />
  )

  const finetunePredictionsVideos = playgroundState?.finetune_predictions_video || [];

  if (finetunePredictionsVideos.length === 0) {
    return (
      <div className="h-screen flex flex-col justify-center items-center bg-white">
        <div className="text-center p-6 max-w-md rounded-lg border border-gray-200 shadow-md">
          <h1 className="text-2xl font-semibold text-gray-800 mb-4">There's nothing here yet!</h1>
          <p className="text-gray-600">
            Go back to the playground to make some videos first.
          </p>
          <button
          className="mt-6 px-4 py-2 text-sm font-medium text-white bg-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg shadow-sm hover:shadow-md transition duration-300"
          onClick={navigateBack}
          >
            Go to Playground
          </button>
        </div>
      </div>
    );
  }

  return (
      <div>
        <div className="w-screen">
          <MenuBar />
          <div className='mt-10 w-full h-full flex flex-col gap-2'>
            <div>
              <button className='mx-2 mt-4 text-blue-700 text-sm' onClick={navigateBack}>
              Back to Playground
              </button>
            </div>
            <VideoComposer
              combinedVideosRef={combinedVideosRef}
              videoClips={videoClips}
              setVideoClips={setVideoClips}
              audioClips={audioClips}
              setAudioClips={setAudioClips}
            />
            <AssetViewer
              finetunePredictions={finetunePredictionsVideos}
              uploadedVideos={uploadedVideos}
              userId={username}
              onAddToComposer={addToComposer}
            />
            <AudioClips
              audioClips={playgroundState?.finetune_predictions_audio || []}
              addToComposer={addAudioToComposer}
            />     
            <div ref={combinedVideosRef}>
              <CompletedVideos completedVideos={completedVideos} />
            </div>
        </div>
        </div>
        <ImageModal isOpen={imageModalOpen} onClose={onImageModalClose} imageUrls={imagesForModal}/>
      </div>
  );

}


export default ComposeVideo;