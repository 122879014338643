
import { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import { db, } from '../Firebase.js' 
import { doc, onSnapshot } from 'firebase/firestore';

import { useUser } from '../contexts/UserContext';
import { getCharactersByEmail, formatCharacterName } from '../Firebase.js' 
import { formatDistanceToNow, parseISO } from 'date-fns';
import BarLoader from 'react-spinners/BarLoader';
import DiscontinuedMessageScreen from '../Components/DiscontinuedMessageScreen.jsx';


import MenuBar from '../MenuBar'

export default function ProfilePage() {

	const {currentUser, loading} = useUser();
  const { specialState } = useParams();
  const [userData, setUserData] = useState(null)

  const navigate = useNavigate();
  const username = currentUser?.email || currentUser?.uid;
  console.log(username);
	
  useEffect(() => {
    if (!username) return;
    const docRef = doc(db, "character-users", username);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data()
        setUserData(data);
      } else {
        console.log("No such document!");
      }
    });

    return () => {
      unsubscribe();
    };
  }, [currentUser]);

  if (loading) return <div>Loading...</div>;
  if (currentUser == null) {
    const loginPath = specialState ? `/login/${specialState}` : '/login';
    navigate(loginPath);
  }
  const customModels = userData?.custom_models || [];

  const goToPlayground = () => {
    const playgroundPath = specialState ? `/playground/${specialState}` : '/playground';
    navigate(playgroundPath);
  };

// ProfilePage Component
// Simple message component for discontinued product

return (
    <DiscontinuedMessageScreen />
)

return (
  <div>
    <div className="w-screen">
      <MenuBar />
      {/* Other content might go here */}
      <div className="mt-12 p-6">
        <div className="text-center mb-8 w-full"> {/* Ensure button spans across all columns */}
          <button
            onClick={goToPlayground}
            className="bg-gradient-to-r from-purple-500 via-purple-400 to-pink-400 hover:from-purple-400 hover:via-purple-300 hover:to-purple-200 text-white text-xl py-6 px-4 font-bold rounded-lg w-full sm:w-3/4 shadow-lg transform transition duration-500 hover:scale-105 cursor-pointer"
          >
            Start making videos
          </button>
        </div>
        <div className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8 gap-6 '>
            <div className="aspect-w-1 aspect-h-1 flex">
              <AddNewButton handleClick={() => navigate('/create')} />
            </div>
            <div className="aspect-w-1 aspect-h-1 flex">
              <UploadCharacterCard />
            </div>
          {customModels.map((model, index) => (
            <div key={index} className="aspect-w-1 aspect-h-1 flex">
              <CharacterCard
                modelInfo={model}
                onClick={() => model.status == 'complete' ? navigate(`/playground`) : null}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);
}

const UploadCharacterCard = () => {
  return (
    <div 
      className="flex items-center justify-center bg-blue-500 text-white font-bold p-4 rounded-lg cursor-pointer min-h-72 transform transition duration-200 hover:scale-105"
      onClick={() => window.open('/upload', '_blank')}
      style={{ transition: 'transform 0.2s ease, background-color 0.2s ease' }}
    >
      Upload yourself or a friend
    </div>
  );
};

const AddNewButton = ({ handleClick }) => {
  return (
    <div 
      className="flex bg-green-600 rounded-lg shadow-lg overflow-hidden transform transition duration-500 hover:scale-105 cursor-pointer text-white w-full justify-center items-center font-bold text-center p-4"
      onClick={handleClick}
    >
      Create character
    </div>
  )
};

// CharacterCard Component
function CharacterCard({ modelInfo, onClick }) {
  return (
    <div
      className="relative flex flex-col max-w-sm rounded overflow-hidden shadow-lg ease-in-out w-full h-full transform transition duration-200 hover:scale-105 cursor-pointer"
      onClick={onClick}
    >
      <img className="w-full" src={modelInfo.low_quality_reference_face_url || modelInfo.reference_face_url} alt={modelInfo.character_name} />
      {modelInfo.status !== "complete" && (
        // Adjust the overlay to cover the entire image area
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-70 flex justify-center items-center text-white">
          <div className="text-center p-2 m-2">
            <p className='text-sm py-2'>We're making {modelInfo.character_name}. </p>
            <p className='text-sm py-2'>Check back in 15 minutes</p>
          </div>
        </div>
      )}
      <div className="px-4 py-2 flex-1 flex flex-col">
        <div className="font-bold text-md mb-2 truncate">{modelInfo.character_name}</div>
        <p className="text-gray-700 text-sm truncate">{modelInfo.character_type}</p>
        <p className="flex-1 text-xs text-gray-700 mr-2 mb-2 truncate">{modelInfo.character_details}</p>
        {modelInfo.status !== "complete" && (
          <div className="flex items-center w-full justify-center mt-auto">
            <BarLoader color="#4A90E2" />
          </div>
        )}
      </div>
    </div>
  );
}
