import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { db } from '../Firebase.js';
import {
  doc,
  onSnapshot
} from 'firebase/firestore';

import { getEndpoint, logEvent } from '../utils';
import RelatedVideos from './RelatedVideos';

import { Loader } from 'lucide-react';
import { Sparkle } from 'lucide-react';
import { ArrowRight } from 'lucide-react';
import { Ellipsis } from 'lucide-react';
import { Check } from 'lucide-react';
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";

import { v4 as uuidv4 } from 'uuid';


/**
 * Pro (Advanced) Remix Editor
 */
const ProRemixEditor = ({
  scene,
  lines,
  setLines,
  username,
  navigate
}) => {
  const [proDocumentId, setProDocumentId] = useState(null);
  const [lineAudios, setLineAudios] = useState({});      // Map of line index to array of audio objects
  const [selectedAudios, setSelectedAudios] = useState({}); // Map of line index to selected audio ID
  const [playingAudioId, setPlayingAudioId] = useState(null);
  
  const [linePending, setLinePending] = useState(
    [...Array(lines.length)].map(() => false)
  );
  const [linePendingIds, setLinePendingIds] = useState(
    [...Array(lines.length)].map(() => null)
  );

  const audioRefs = useRef({});
  const CHARACTER_LIMIT = 300;
  const MAX_AUDIOS = 5;
  const [isSubmitting, setIsSubmitting] = useState(false);

  // =============
  // Real-time updates for the pro doc once we have a proDocumentId
  // =============
  useEffect(() => {
    if (!proDocumentId) return;

    const unsubscribe = onSnapshot(
      doc(db, 'remixes-pro', proDocumentId),
      (docSnap) => {
        if (!docSnap.exists()) {
          console.log("Document doesn't exist:", proDocumentId);
          return;
        }
        
        const data = docSnap.data();
        console.log(">> Received document update:", data);

        if (data.line_audios) {
          // Check for completion of pending generations
          Object.entries(data.line_audios).forEach(([index, audios]) => {
            const lineIndex = parseInt(index);
            const pendingId = linePendingIds[lineIndex];
            if (pendingId) {
              const pendingAudio = audios.find(audio => audio.id === pendingId);
              if (pendingAudio && pendingAudio.status === 'COMPLETED') {
                setLinePendingIds(prev => {
                  const next = [...prev];
                  next[lineIndex] = null;
                  return next;
                });
                
                setLinePending(prev => {
                  const next = [...prev];
                  next[lineIndex] = false;
                  return next;
                });
              }
            }
          });

          // Transform the line_audios data into the format we need
          const transformedAudios = {};
          Object.entries(data.line_audios).forEach(([index, audios]) => {
            transformedAudios[index] = audios
              .map(audio => ({
                url: audio.url,
                id: audio.id,
                length: audio.length,
                timestamp: audio.timestamp,
                status: audio.status
              }))
              // newest first
              .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
          });
          
          setLineAudios(transformedAudios);

          // Auto-select newest audio for each line if none selected
          Object.entries(transformedAudios).forEach(([index, audios]) => {
            if (audios.length > 0 && !selectedAudios[index]) {
              setSelectedAudios(prev => ({
                ...prev,
                [index]: audios[0].id
              }));
            }
          });
        }
        
        if (data.error_message) {
          console.error('Error from backend:', data.error_message);
          // Optionally, show error to user
        }
      },
      (error) => {
        console.error("Error listening to document:", error);
      }
    );

    return () => {
      console.log("Cleaning up document listener");
      unsubscribe();
    };
  }, [proDocumentId, linePendingIds]);

  // =============
  // Text changes
  // =============
  const handleTextChange = (index, value) => {
    setLines(prev => {
      const newLines = [...prev];
      newLines[index].text = value.slice(0, CHARACTER_LIMIT);
      return newLines;
    });
  };

  // Generate an audio for a single line
  const generateAudio = async (lineIndex) => {
    const lineText = lines[lineIndex].text;
    if (!lineText) return;

    const gen_id = uuidv4();

    // Mark line as pending
    setLinePending(prev => {
      const next = [...prev];
      next[lineIndex] = true;
      return next;
    });
    
    setLinePendingIds(prev => {
      const next = [...prev];
      next[lineIndex] = gen_id;
      return next;
    });

    try {
      const requestBody = {
        line_text: lineText,
        voice_id: lines[lineIndex].speaker_id,
        line_index: lineIndex,
        scene_document: scene,
        gen_id: gen_id,
      };

      // If we've already created a pro doc, reference it
      if (proDocumentId) {
        requestBody.document_id = proDocumentId;
      } else {
        requestBody.scene_document = scene;
      }

      const response = await fetch(`${getEndpoint()}/remix-scene-pro-audio`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Audio generation response:", data);

        // On first generation, store the pro doc ID
        if (!proDocumentId) {
          setProDocumentId(data.document_id);
        }
      } else {
        const errorData = await response.json();
        console.error('Error from server:', errorData.error);
      }
    } catch (error) {
      console.error('Error generating audio:', error);
    }
  };

  // Audio player controls
  const handleAudioPlay = (audioId) => {
    // Pause any currently playing audio
    if (playingAudioId && audioRefs.current[playingAudioId]) {
      audioRefs.current[playingAudioId].pause();
    }
    // Play the newly selected audio
    if (audioRefs.current[audioId]) {
      audioRefs.current[audioId].play();
      setPlayingAudioId(audioId);
    }
  };

  // Submit final Pro remix
  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await logEvent({
        eventName: 'remix-submit-pro-remix',
        sceneId: scene.id,
        title: scene.title,
        user: username,
      });

      // For each line, find the selected audio's URL
      const audioUrls = lines.map((_, index) => {
        const selectedAudioId = selectedAudios[index];
        const audiosForLine = lineAudios[index] || [];
        const selectedAudio = audiosForLine.find(audio => audio.id === selectedAudioId);
        if (!selectedAudio) {
          throw new Error(`No selected audio found for line ${index + 1}`);
        }
        return selectedAudio.url;
      });

      const response = await fetch(`${getEndpoint()}/remix-scene-pro-submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          // scene_document: {
          //   id: scene.id,
          //   thumbnail_url: scene.thumbnail_url,
          //   video_url: scene.video_url,
          //   preview_gif_url: scene.preview_gif_url,
          //   title: scene.title,
          //   speaker_ids: scene.speaker_ids,
          //   scene: scene.scene,
          //   skip_lipsync: scene.skip_lipsync,
          // },
          scene_document: scene,
          lines: lines.map(line => ({ text: line.text })),
          audio_urls: audioUrls,
        }),
      });

      if (response.ok) {
        const { document_id } = await response.json();
        navigate(`/mix/${document_id}`);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to submit remix');
      }
    } catch (error) {
      console.error('Error submitting pro remix:', error);
    }
    setIsSubmitting(false);
  };

  // Basic placeholder logic (used for line input)
  const getPlaceholder = (line) => {
    if (line.original_text) {
      return `${line.original_text.slice(
        0,
        100 + line.original_text.slice(100).indexOf(' ')
      )}...`;
    } else {
      return 'Enter text';
    }
  };

  // Submission enabled if we have a proDocumentId (once at least one generation has started)
  // and each line has a selected audio
  const canSubmit =
    proDocumentId &&
    Object.keys(selectedAudios).length === lines.length;

  return (
    <div className="space-y-4">
      {lines.map((line, index) => (
        <div
          key={index}
          className="bg-gray-900 px-3 p-4 rounded-lg border border-gray-800 transition-all hover:border-gray-700"
        >
          <div className="flex justify-between mb-2">
            <span className="text-gray-400">
              {line.speaker_id
                .split(/[_-]/)
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ')}
            </span>
            <span className="text-gray-400">{line.duration.toFixed(1)}s</span>
          </div>

          {/* Input + Generate Button */}
          <div className="mb-4">
            <div className="flex gap-2">
              <input
                type="text"
                value={line.text}
                onChange={(e) => handleTextChange(index, e.target.value)}
                className="w-full p-3 rounded-lg bg-gray-800 border border-gray-700 focus:border-gray-500 focus:outline-none transition"
                placeholder={getPlaceholder(line)}
              />
              <button
                onClick={() => generateAudio(index)}
                disabled={
                  !line.text ||
                  (lineAudios[index]?.length >= MAX_AUDIOS) ||
                  linePending[index]
                }
                className="flex items-center justify-center rounded-lg disabled:bg-red-600 bg-green-500 p-2"
              >
                {linePending[index] ? (
                  <Loader className="w-6 h-6 animate-spin text-gray-400" />
                ) : (
                  <ArrowRight className="w-5 h-5 text-green-900" strokeWidth={3} />
                )}
              </button>
            </div>
            <p className='text-center pt-2 text-gray-500'>Pro mode: You can generate audios up to 5 times before creating your scene</p>
            {line.text.length >= CHARACTER_LIMIT && (
              <p className="text-red-500 text-xs mt-1">Maximum character limit reached</p>
            )}
            {lineAudios[index]?.length >= MAX_AUDIOS && (
              <div className="text-center mt-1">
                <span className="text-red-400 text-xs">Max audio generations reached</span>
              </div>
            )}
          </div>

          {/* Audio Samples for this line */}
          {lineAudios[index]?.length > 0 && (
            <div className="flex gap-2 overflow-x-auto pb-2">
              {lineAudios[index].map((audio, audioIndex) => {
                const isSelected = selectedAudios[index] === audio.id;
                const isProcessing = audio.status === 'PROCESSING';

                return (
                  <div
                    key={audio.id}
                    className={`flex flex-col items-center min-w-[80px] p-2 rounded-lg transition-all bg-gray-800 
                      ${isSelected ? 'border border-green-500' : ''}`}
                    onClick={() => {
                      if (!isProcessing) {
                        setSelectedAudios(prev => ({
                          ...prev,
                          [index]: audio.id
                        }));
                      }
                    }}
                  >
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!isProcessing) {
                          handleAudioPlay(audio.id);
                        }
                      }}
                      disabled={isProcessing}
                      className={`w-12 h-12 flex items-center justify-center bg-gray-800 rounded-lg 
                        ${!isProcessing ? 'hover:bg-gray-700' : 'cursor-not-allowed'}`}
                    >
                      {isProcessing ? (
                        <Ellipsis className="w-6 h-6 text-gray-400" />
                      ) : playingAudioId === audio.id ? (
                        <FaPause className="w-6 h-4 text-gray-500" />
                      ) : (
                        <FaPlay className="w-6 h-4 text-gray-500" />
                      )}
                    </button>
                    {/* Audio tag for playback */}
                    {!isProcessing && (
                      <audio
                        ref={el => audioRefs.current[audio.id] = el}
                        src={audio.url}
                        onEnded={() => setPlayingAudioId(null)}
                      />
                    )}
                    <div className="text-center mt-1">
                      <div
                        className={`text-sm text-gray-400 ${
                          isSelected ? 'text-green-500 font-bold' : ''
                        }`}
                      >
                        Gen-{lineAudios[index].length - audioIndex}
                      </div>
                      <div className="text-xs text-gray-500">
                        {(!isProcessing && audio.length)
                          ? `${audio.length.toFixed(1)}s`
                          : 'Processing...'}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ))}

      <button
        onClick={handleSubmit}
        disabled={isSubmitting || !canSubmit}
        className="my-6 w-full py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 disabled:bg-gray-700 disabled:cursor-not-allowed transition-colors duration-200 font-bold"
      >
        {isSubmitting ? 'Creating...' : 'Create Remix'}
      </button>
    </div>
  );
};

export default ProRemixEditor;