import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { useUser } from '../contexts/UserContext';
import { db } from '../Firebase.js';
import {
  collection,
  onSnapshot,
  setDoc,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import InputForm from './InputForm.jsx';
import MenuBar from '../MenuBar';
import ImageModal from '../Components/ImageModal.jsx';
import ResultsGrid from '../Components/ResultsGrid.jsx';
import AddSpeechModal from '../Components/AddSpeechModal.jsx';
import { hpPreloadAssetArray } from '../AssetPreloads/HpPreloadAssetArray.js';
import { defaultPreloadAssetArray } from '../AssetPreloads/defaultPreloadAssetArray.js';
import AllMotionsModal from './AllMotionsModal.jsx'
import FirebaseRateLimiter from './FirebaseRateLimiter';
import { getEndpoint } from '../utils';
import DiscontinuedMessageScreen from '../Components/DiscontinuedMessageScreen.jsx';


function Playground() {
  const { specialState } = useParams();
	const {currentUser, loading, idToken} = useUser();

  const username = currentUser?.email || currentUser?.uid;
  const { addRequest, checkLimit, isLimited } = FirebaseRateLimiter(username);

  const [isSpecialLoading, setIsSpecialLoading] = useState(false);
  const [playgroundState, setPlaygroundState] = useState({ finetune_predictions: [] });

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imagesForModal, setImagesForModal] = useState(false);
  const [addSpeechModalOpen, setAddSpeechModalOpen] = useState(false);
  
  // TODO: use a context for this so data doesn't have to
  // be passed from grid item to playground
  const [currentImageInfo, setCurrentImageInfo] = useState({});
  const [allMotionsModalOpen, setAllMotionsModalOpen] = useState(false);
  // const [allPosesModalOpen, setAllPosesModalOpen] = useState(false);

	const navigate = useNavigate();
  let hasGeneratedContent = playgroundState?.finetune_predictions?.length > 0;

  useEffect(() => {
    if (specialState === "hp") {  
      // Filter out any preload items that are already included in finetune_predictions
      const newPreloadData = hpPreloadAssetArray.filter(preloadItem =>
        !playgroundState.finetune_predictions.some(prediction => prediction.prediction_id === preloadItem.prediction_id)
      );
  
      if (newPreloadData.length > 0) {
        setPlaygroundState((prevState) => ({
          ...prevState,
          finetune_predictions: [
            ...newPreloadData,
            ...prevState.finetune_predictions
          ]
        }));
      }
      setIsSpecialLoading(true);
    } else if (!specialState) {
      // Load defaultPreloadAssetArray when no special state is present
      const newPreloadData = defaultPreloadAssetArray.filter(preloadItem =>
        !playgroundState.finetune_predictions.some(prediction => prediction.prediction_id === preloadItem.prediction_id)
      );
  
      if (newPreloadData.length > 0) {
        setPlaygroundState((prevState) => ({
          ...prevState,
          finetune_predictions: [
            ...newPreloadData,
            ...prevState.finetune_predictions
          ]
        }));
      }
      setIsSpecialLoading(true);
    } else {
      setIsSpecialLoading(false);
    }
  }, [specialState, playgroundState.finetune_predictions]);  

  useEffect(() => {
    if (!username) return;
  
    // Existing predictions fetch setup
    const predictionsRef = collection(db, "character-users", username, "finetune_predictions");
    const unsubscribePredictions = onSnapshot(predictionsRef, (querySnapshot) => {
      const predictions = [];
      querySnapshot.forEach((doc) => {
        predictions.push({ id: doc.id, ...doc.data() });
      });
  
      const sortedPredictions = predictions.sort((a, b) => a.timestamp.localeCompare(b.timestamp));
  
      setPlaygroundState((prevState) => ({ ...prevState, finetune_predictions: sortedPredictions }));
    });
  
    // Adjusted custom models fetch setup
    const customModelsRef = doc(db, "character-users", username);
    const unsubscribeCustomModels = onSnapshot(customModelsRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const allCustomModels = docSnapshot.data().custom_models || [];
        // Filter custom models to include only those with status 'complete'
        const completeCustomModels = allCustomModels.filter(model => model.status === 'complete');
        setPlaygroundState((prevState) => ({ ...prevState, custom_models: completeCustomModels }));
      }
    });
  
    // Return a cleanup function that unsubscribes from both listeners
    return () => {
      unsubscribePredictions();
      unsubscribeCustomModels();
    };
  }, [username]);

  const seeImages = (images) => {
    setImagesForModal(images)
    setImageModalOpen(true)

  }
  const onImageModalClose = () => {
    setImagesForModal([])
    setImageModalOpen(false)
  }

  // Function to open the AllPosesModal
  // const openAllPosesModal = () => {
  //   setAllPosesModalOpen(true);
  // };

  if (loading) return <div>Loading...</div>;  
  if (currentUser == null) {
    const loginPath = specialState ? `/login/${specialState}` : '/login';
    navigate(loginPath);
  }
  if (playgroundState == null) return <div>Loading...</div>;


  const onAnimate = async (resIndex, modelURL = "", referenceFaceURL, imgURL, imageType) => {
    console.log('Checking rate limit...');
    const isWithinLimit = await checkLimit();
    console.log('Rate limit check result:', isWithinLimit);

    if (!isWithinLimit) {
      alert("Rate limit exceeded. Please try again later.");
      return;
    }

    await addRequest();
    const endpoint = `${getEndpoint()}/animate`;
  
    // Generate a unique prediction ID
    const predictionId = `${username}-${new Date().toISOString()}`;

    // Create the processing record in Firestore
    const animationProcessingRecord = {
        prediction_id: predictionId,
        model_url: modelURL,
        output_images: [],
        prompt: "",
        negative_prompt: "",
        status: 'PROCESSING',
        asset_type: 'VIDEO',
        timestamp: new Date().toISOString()
    };

    // Reference to Firestore document
    const animationDocRef = doc(db, "character-users", username, "finetune_predictions", predictionId);
    await setDoc(animationDocRef, animationProcessingRecord);

    // Now call the backend with the prediction ID included
    const data = {
        user: username,
        image_url: imgURL,
        reference_face_url: referenceFaceURL,
        model_url: modelURL,
        prediction_id: predictionId,
        image_type: imageType,
        event_name: 'create-video'
    };
    
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,

      },
      body: JSON.stringify(data)
    });
  
    if (response.ok) {
      const responseBody = await response.json();
      console.log(`Successfully sent request. Received data back: ${JSON.stringify(responseBody)}. Redirecting`);
    } else {
      // Handle the error
      console.error('Failed to start animation');
    }    
  };
  
  const onCreateVideo = () => {
    const path = specialState ? `/compose/${specialState}` : '/compose';
    window.open(path, '_blank');  
  }

  const deleteAsset = async (predictionId) => {
    await deleteDoc(doc(db, "character-users", username, "finetune_predictions", predictionId));
  };

  
  const handleAddSpeech = (resIndex, modelUrl, imageUrl) => {
    setCurrentImageInfo({ resIndex, modelUrl, imageUrl });
    setAddSpeechModalOpen(true);
  };
  
  const handleSpeechSubmit = async (talkingText, selectedVoice) => {
    if (!checkLimit()) {
      alert("Rate limit exceeded. Please try again later.");
      return;
    }
    addRequest();
    const { resIndex, modelUrl, imageUrl } = currentImageInfo;
    // Generate a unique prediction ID
    const predictionId = `${username}-${new Date().toISOString()}`;
    // Create the processing record in Firestore
    const speechProcessingRecord = {
      prediction_id: predictionId,
      model_url: modelUrl,
      output_images: [],
      prompt: "",
      negative_prompt: "",
      status: 'PROCESSING',
      asset_type: 'VIDEO',
      timestamp: new Date().toISOString()
    };
    // Reference to Firestore document
    const speechDocRef = doc(db, "character-users", username, "finetune_predictions", predictionId);
    await setDoc(speechDocRef, speechProcessingRecord);

    // Prepare the data for the POST request to the backend
    const data = {
      user: username,
      image_url: imageUrl,
      model_url: modelUrl,
      voice_to_use: selectedVoice,
      talking_text: talkingText,
      prediction_id: predictionId,
      event_name: 'create_talking_clip'
    };
        
    // Sending the POST request to the backend
    const response = await fetch(`${getEndpoint()}/talking_clip`, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}`,

      },
      body: JSON.stringify(data)
    });
    
    if (response.ok) {
      const responseBody = await response.json();
      console.log(`Successfully sent request. Received data back: ${JSON.stringify(responseBody)}`);
    } else {
      // Handle the error
      console.error('Failed to create talking clip');
    }
  };

  const onSubmitMotion = async  (motionUrl) => {

    const endpoint = `${getEndpoint()}/character_motion`;
  
    // Generate a unique prediction ID
    const predictionId = `${username}-${new Date().toISOString()}`;

    // Get the image info that this motion was applied to
    const {resIndex, characterName, modelUrl, modelUrlOriginal, faceUrl, imageUrl, prompt, negativePrompt} = currentImageInfo;

    // Create a fireebase doc for this new motion video
    const motionProcessingRecord = {
        prediction_id: predictionId,
        character_name: characterName,
        model_url: modelUrl,
        model_url_original: modelUrlOriginal,
        output_images: [],
        prompt: prompt,
        negative_prompt: negativePrompt,
        status: 'PROCESSING',
        asset_type: 'VIDEO',
        timestamp: new Date().toISOString()
    };

    // Reference to Firestore document
    const motionDocRef = doc(db, "character-users", username, "finetune_predictions", predictionId);
    await setDoc(motionDocRef, motionProcessingRecord);


    // Now call the backend with the prediction ID included
    const data = {
        user: username,
        character_name: characterName,
        image_url: imageUrl,
        reference_face_url: faceUrl,
        model_url: modelUrl,
        model_url_original: modelUrlOriginal,
        prompt: prompt,
        negative_prompt: negativePrompt,
        motion_url: motionUrl,
        prediction_id: predictionId,
        image_type: "VIDEO",
        event_name: 'character_motion'
    };
    
    console.log("Calling /character_motion with data: ", data);
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,

      },
      body: JSON.stringify(data)
    });
  
    if (response.ok) {
      const responseBody = await response.json();
      console.log(`Successfully sent request. Received data back: ${JSON.stringify(responseBody)}. Redirecting`);
    } else {
      // Handle the error
      console.error('Failed to start animation');
    }   

  }

  const handleAddMotion = (resIndex, characterName, modelUrl, modelUrlOriginal, faceUrl, imageUrl, prompt, negativePrompt) => {
    setCurrentImageInfo({resIndex, characterName, modelUrl, modelUrlOriginal, faceUrl, imageUrl, prompt, negativePrompt});
    setAllMotionsModalOpen(true)
  }

  return (
    <DiscontinuedMessageScreen />
  )

  return (
      <div>
        <div className="w-screen">
          <MenuBar />
          <div className='mt-10 w-full h-full flex flex-col md:flex-row'>
            <div className='w-full md:min-h-screen md:w-1/4 bg-gray-100'>
              <InputForm
                user={username}
                playgroundState={playgroundState}
                checkLimit={checkLimit}
                addRequest={addRequest}
                isLimited={isLimited}
              />           
            </div>
            <div className='w-full bg-gray-200 px-4 md:min-h-screen md:w-3/4 py-4'>
              {hasGeneratedContent ? (
                <>
                  <div className='flex flex-row justify-between py-2'>
                    <p className='text-xl font-bold text-gray-800 py-2'>Generated Scenes</p>
                    <button
                      onClick={onCreateVideo}
                      className='bg-gray-900 text-gray-200 rounded text-sm py-2 px-2 hover:shadow-md hover:shadow-indigo-500/50 transition-shadow duration-300 '
                    >
                      Combine and Edit Videos ✂️
                    </button>
                  </div>
                  <ResultsGrid
                    results={playgroundState.finetune_predictions}
                    onDeleteAsset={deleteAsset}
                    showDeleteButton={true}
                    captionKey={'prompt'}
                    showImages={seeImages}
                    photoType={null}
                    showActionButton={true}
                    actionButtonText={'Animate'}
                    onAction={onAnimate}
                    onAddSpeech={handleAddSpeech}
                    onAddMotion={handleAddMotion}
                    specialState={specialState}
                    currentUser={currentUser}
                  />
                  <AddSpeechModal
                    isOpen={addSpeechModalOpen}
                    onClose={() => setAddSpeechModalOpen(false)}
                    onSubmit={handleSpeechSubmit}
                  />
                  <AllMotionsModal
                    isOpen={allMotionsModalOpen}
                    onClose={() => setAllMotionsModalOpen(false)}
                    onMotionSelect={onSubmitMotion}
                  />
                </>
              ) : (
                <div className="p-10 m-4 bg-gradient-to-r from-purple-500 to-pink-500 rounded-lg shadow-lg">
                  <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">✨ Create your first movie scene!</h2>
                  <ul className="list-decimal list-inside text-lg font-medium text-white space-y-4">
                    <li>Choose your creation mode</li>
                    <li>Compose your prompt</li>
                    <li>Watch AI generate your scene in seconds</li>
                  </ul>
                </div>

              )}
            </div>
          </div>
        </div>
        <ImageModal isOpen={imageModalOpen} onClose={onImageModalClose} imageUrls={imagesForModal}/>
        {/*<IntroModal isOpen={introModalOpen} onClose={onIntroModalClose}/>*/}

        
      </div>
  );

}

export default Playground;