import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

// 1) Add these imports for the map
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { getAuth } from 'firebase/auth'; // ← Import from Firebase
import { db } from '../Firebase.js'; // Just to be consistent if you need it, though not always necessary

const MAPBOX_TOKEN = 'pk.eyJ1Ijoic3BsYW5rMjYiLCJhIjoiY201a2Y1NTBnMWZqOTJxb21ibm90OWNrZiJ9.6e_UFmi7XUcz1HftFcdcBg';

const TimePortalCreator = () => {
    const [rounds, setRounds] = useState([
        { eventName: '', locationName: '', year: 2000, latitude: null, longitude: null },
        { eventName: '', locationName: '', year: 2000, latitude: null, longitude: null },
        { eventName: '', locationName: '', year: 2000, latitude: null, longitude: null },
        { eventName: '', locationName: '', year: 2000, latitude: null, longitude: null },
        { eventName: '', locationName: '', year: 2000, latitude: null, longitude: null },
    ]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const [isHoldingLeft, setIsHoldingLeft] = useState(false);
    const [isHoldingRight, setIsHoldingRight] = useState(false);
    const [dailyChallengeDate, setDailyChallengeDate] = useState(new Date().toISOString().split('T')[0]);
    const [isDragging, setIsDragging] = useState(false);

    const [isAuthorized, setIsAuthorized] = useState(false);
    const [loadingAuth, setLoadingAuth] = useState(true);

    // On mount, check authorization
    useEffect(() => {
        const auth = getAuth();
        const allowedEmails = ['smgplank@gmail.com', 'jitsvm@gmail.com'];
        
        const unsubscribeAuth = auth.onAuthStateChanged((user) => {
            if (user && allowedEmails.includes(user.email)) {
                setIsAuthorized(true);
            } else {
                setIsAuthorized(false);
            }
            setLoadingAuth(false);
        });
        
        return () => unsubscribeAuth();
    }, []);

    const formatYear = (year) => {
        return year < 0 ? Math.abs(year) + " BC" : year + " AD";
    };

    const handleRoundChange = (index, field, value) => {
        const newRounds = [...rounds];
        newRounds[index] = {
            ...newRounds[index],
            [field]: field === 'year' ? parseInt(value) : value
        };
        setRounds(newRounds);
    };

    const handleYearChange = (index, direction) => {
        const step = 1;
        const newYear = direction === 'left' 
            ? Math.max(-3000, rounds[index].year - step)
            : Math.min(2025, rounds[index].year + step);
        
        handleRoundChange(index, 'year', newYear);
    };

    // 2) Handle map clicks for each round
    const handleMapClick = (e, index) => {
        // e.lngLat is from react-map-gl
        const { lat, lng } = e.lngLat;
        const newRounds = [...rounds];
        newRounds[index].latitude = lat;
        newRounds[index].longitude = lng;
        setRounds(newRounds);
    };

    const handleDragStart = (e, index) => {
        if (e.target !== e.currentTarget) {
            return;
        }
        setIsDragging(true);
        handleDrag(e, index);
    };

    const handleDrag = (e, index) => {
        if (!isDragging) return;
        
        const timeline = e.currentTarget;
        const rect = timeline.getBoundingClientRect();
        const x = e.type.includes('touch') ? e.touches[0].clientX - rect.left : e.clientX - rect.left;
        const percentage = Math.max(0, Math.min(1, x / rect.width));
        
        // Convert percentage to year (-3000 to 2025)
        const rawYear = -3000 + (percentage * 5025);
        const newYear = Math.round(rawYear);
        handleRoundChange(index, 'year', newYear);
    };

    useEffect(() => {
        const handleGlobalPointerUp = () => {
            setIsDragging(false);
        };
        document.addEventListener('pointerup', handleGlobalPointerUp);
        return () => {
            document.removeEventListener('pointerup', handleGlobalPointerUp);
        };
    }, []);

    const renderYearInput = (round, index) => (
        <div>
            <label htmlFor={`time-${index}`} className="block text-sm font-medium text-gray-700 mb-1">
                Year
            </label>
            <div className="relative w-full h-32">
                <div 
                    className="absolute w-[calc(100%-80px)] h-12 bg-gray-200 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 cursor-grab active:cursor-grabbing rounded"
                    onMouseDown={(e) => handleDragStart(e, index)}
                    onTouchStart={(e) => handleDragStart(e, index)}
                    onMouseMove={(e) => handleDrag(e, index)}
                    onTouchMove={(e) => handleDrag(e, index)}
                    style={{ touchAction: 'none' }}
                >
                    {/* Left arrow button */}
                    <button
                        className="absolute -left-10 top-1/2 transform -translate-y-1/2 w-8 h-8 bg-transparent text-gray-700 rounded-lg transition-all duration-300 border border-gray-300 hover:border-blue-500 hover:bg-blue-50 flex items-center justify-center"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleYearChange(index, 'left');
                        }}
                    >
                        ←
                    </button>

                    {/* Right arrow button */}
                    <button
                        className="absolute -right-10 top-1/2 transform -translate-y-1/2 w-8 h-8 bg-transparent text-gray-700 rounded-lg transition-all duration-300 border border-gray-300 hover:border-blue-500 hover:bg-blue-50 flex items-center justify-center"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleYearChange(index, 'right');
                        }}
                    >
                        →
                    </button>

                    {/* Year indicator */}
                    <div 
                        className="absolute w-1 h-16 bg-blue-500 top-1/2 transform -translate-y-1/2"
                        style={{ 
                            left: `${((round.year + 3000) / 5025) * 100}%`
                        }}
                    >
                        <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                            <span className="text-white text-sm font-medium px-2 py-1 rounded bg-blue-500">
                                {formatYear(round.year)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const renderMap = (round, index) => (
        <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
                Pick Coordinates on Map
            </label>
            <div className="w-full h-64 rounded overflow-hidden">
                <Map
                    mapboxAccessToken={MAPBOX_TOKEN}
                    initialViewState={{
                        longitude: round.longitude || 0,
                        latitude: round.latitude || 0,
                        zoom: 1
                    }}
                    mapStyle="mapbox://styles/mapbox/streets-v12"
                    projection="mercator"
                    onClick={(e) => handleMapClick(e, index)}
                    style={{ width: '100%', height: '100%' }}
                >
                    {round.latitude && round.longitude && (
                        <Marker
                            longitude={round.longitude}
                            latitude={round.latitude}
                            anchor="bottom"
                        >
                            <svg 
                                width="24" 
                                height="36" 
                                viewBox="0 0 24 36" 
                                fill="red"
                                stroke="white"
                                strokeWidth="2"
                                style={{ transform: 'translate(-0px, -32px)' }}
                            >
                                <path d="M12 0C5.373 0 0 5.373 0 12c0 10 12 24 12 24s12-14 12-24c0-6.627-5.373-12-12-12z" />
                            </svg>
                        </Marker>
                    )}
                </Map>
            </div>
            {round.latitude && round.longitude && (
                <p className="text-gray-700 mt-2 text-sm">
                    Latitude: {round.latitude.toFixed(4)}, Longitude: {round.longitude.toFixed(4)}
                </p>
            )}
        </div>
    );

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const roundIds = Array(5).fill(null).map(() => uuidv4());
            const firstRoundId = roundIds[0];

            for (let i = 0; i < 5; i++) {
                const currentRoundId = roundIds[i];
                const nextRoundId = i < 4 ? roundIds[i + 1] : null;

                // Get the current timezone offset in minutes
                const currentDate = new Date(dailyChallengeDate);
                const timezoneOffsetMinutes = currentDate.getTimezoneOffset();
                // Convert to hours (will be negative for EST/EDT)
                const timezoneOffsetHours = -timezoneOffsetMinutes / 60;
                
                // Set to 9:00 AM in the local timezone
                currentDate.setHours(9, 0, 0, 0);
                
                // Convert to UTC for storage
                const utcDate = new Date(currentDate.getTime() + timezoneOffsetMinutes * 60000);
                const isoDateTime = utcDate
                    .toISOString()
                    .replace('T', ' ')
                    .split('.')[0];

                const payload = {
                    event_name: rounds[i].eventName,
                    location_name: rounds[i].locationName,
                    year: rounds[i].year,
                    round_id: currentRoundId,
                    round_number: i + 1,
                    first_round: firstRoundId,
                    next_round: nextRoundId,
                    utc_offset: timezoneOffsetHours, // Dynamic offset based on current timezone
                    ...(rounds[i].latitude !== null && { latitude: rounds[i].latitude }),
                    ...(rounds[i].longitude !== null && { longitude: rounds[i].longitude }),
                    ...(i === 0 && { daily_challenge_date: isoDateTime })
                };

                const response = await fetch(
                    // `https://timetravel-dd209e3dce1f.herokuapp.com/generate-location-images`,
                    `http://127.0.0.1:2999/generate-location-images`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    }
                );

                const data = await response.json();

                if (data.status !== 'success') {
                    throw new Error(`Failed to create round ${i + 1}`);
                }

                if (i === 0) {
                    navigate(`/timeportal/${currentRoundId}`);
                }
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error creating time portal. Please try again.');
            setIsSubmitting(false);
        }
    };

    // Wait until we've checked auth. Then if not authorized, show message.
    if (loadingAuth) {
        return <div className="text-center p-8">Checking authorization...</div>;
    }

    if (!isAuthorized) {
        return (
            <div className="container mx-auto p-4 text-center">
                <h1 className="text-2xl font-bold mb-6">Access Denied</h1>
                <p>You do not have permission to view this content.</p>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-100 py-8">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-3xl font-bold mb-8 text-center">Create Time Portal Game</h1>
                
                <form onSubmit={handleSubmit} className="space-y-8">
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-xl font-semibold mb-4">Daily Challenge Date</h2>
                        <input
                            type="date"
                            value={dailyChallengeDate}
                            onChange={(e) => setDailyChallengeDate(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    {rounds.map((round, index) => (
                        <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                            <h2 className="text-xl font-semibold mb-4">Round {index + 1}</h2>
                            
                            <div className="space-y-4">
                                <div>
                                    <label htmlFor={`event-${index}`} className="block text-sm font-medium text-gray-700 mb-1">
                                        Event Name
                                    </label>
                                    <input
                                        type="text"
                                        id={`event-${index}`}
                                        value={round.eventName}
                                        onChange={(e) => handleRoundChange(index, 'eventName', e.target.value)}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="e.g., The Fall of Rome"
                                        required
                                    />
                                </div>

                                <div>
                                    <label htmlFor={`location-${index}`} className="block text-sm font-medium text-gray-700 mb-1">
                                        Location
                                    </label>
                                    <input
                                        type="text"
                                        id={`location-${index}`}
                                        value={round.locationName}
                                        onChange={(e) => handleRoundChange(index, 'locationName', e.target.value)}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="e.g., Rome, Italy"
                                        required
                                    />
                                </div>

                                {renderMap(round, index)}

                                {renderYearInput(round, index)}
                            </div>
                        </div>
                    ))}

                    <div className="flex justify-center">
                        <button
                            type="submit"
                            disabled={
                                isSubmitting ||
                                rounds.some(round => !round.eventName || !round.locationName)
                            }
                            className={`px-8 py-3 rounded-md text-white font-medium 
                                ${(isSubmitting || rounds.some(round => !round.eventName || !round.locationName))
                                    ? 'bg-gray-400 cursor-not-allowed' 
                                    : 'bg-blue-500 hover:bg-blue-600'
                                }`}
                        >
                            {isSubmitting ? 'Creating...' : 'Create Game'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default TimePortalCreator; 