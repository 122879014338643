import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { db } from '../Firebase.js';
import {
  doc,
  onSnapshot
} from 'firebase/firestore';

import { getEndpoint, logEvent } from '../utils';
import RelatedVideos from './RelatedVideos';

import { Loader } from 'lucide-react';
import { Sparkle } from 'lucide-react';
import { ArrowRight } from 'lucide-react';
import { Ellipsis } from 'lucide-react';
import { Check } from 'lucide-react';
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";

import { v4 as uuidv4 } from 'uuid';
import BasicRemixEditor from './BasicRemixEditor';
import ProRemixEditor from './ProRemixEditor';
const RemixEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useUser();
  const username = currentUser?.email || currentUser?.uid;

  const [scene, setScene] = useState(null);
  const [docExists, setDocExists] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isProMode, setIsProMode] = useState(false);

  // We'll store lines in the parent so we can preserve them even if mode toggles
  const [lines, setLines] = useState([]);

  const videoRef = useRef(null);

  // Fetch the main scene document
  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, 'remix-scenes-stock', id),
      (docSnap) => {
        setLoading(false);

        if (!docSnap.exists()) {
          setDocExists(false);
          return;
        }
        setDocExists(true);

        const sceneData = { id: docSnap.id, ...docSnap.data() };
        setScene(sceneData);

        // Initialize lines from the scene, if not already set
        if (sceneData.scene) {
          const initialLines = sceneData.scene.map((segment) => ({
            speaker_id: segment.speaker_id,
            duration: segment.end - segment.start,
            original_text: segment.original_text,
            text: '', // user’s typed text
          }));
          setLines(initialLines);
        }
      },
      (error) => {
        console.error('Firestore onSnapshot error:', error);
        setLoading(false);
        setDocExists(false);
      }
    );

    return () => unsubscribe();
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen bg-black flex flex-col items-center justify-center gap-4">
        <p className="text-gray-400 text-lg">Loading…</p>
      </div>
    );
  }

  if (!docExists) {
    return (
      <div className="min-h-screen bg-black flex flex-col items-center justify-center gap-4">
        <p className="text-gray-400 text-lg">
          Hm, we couldn't find a remix with that ID
        </p>
        <a
          href="https://www.eggnog.ai/remix"
          className="text-green-400 hover:text-green-300 transition-colors"
        >
          Go home
        </a>
      </div>
    );
  }

  if (!scene?.scene) {
    return (
      <div className="min-h-screen bg-black flex flex-col items-center justify-center gap-4">
        <p className="text-gray-400 text-lg">Hm, we couldn't find a remix</p>
        <a
          href="https://www.eggnog.ai/remix"
          className="text-green-400 hover:text-green-300 transition-colors"
        >
          Go home
        </a>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Header */}
      <header className="sticky top-0 bg-black/90 backdrop-blur-sm p-4 z-10">
        <h1 className="text-2xl font-bold text-center">🥚 remix </h1>
        <h2 className="text-md text-center text-gray-400 mt-2">
          Remix popular movie scenes, TV shows, and current events ✨
        </h2>
      </header>

      <div className="max-w-4xl mx-auto px-2 py-4">
        <button
          onClick={() => navigate('/remix')}
          className="text-gray-400 hover:text-white transition my-4"
        >
          ← Back to all scenes
        </button>

        <div className="mb-8">
          <video
            ref={videoRef}
            src={scene.video_url}
            controls
            autoPlay
            muted
            playsInline
            onLoadedData={() => {
              if (videoRef.current) {
                videoRef.current.volume = 1;
              }
            }}
            className="w-full rounded-lg shadow-lg border-2 border-gray-700"
          />
        </div>

        {/* Toggle Basic / Pro mode */}
        <div className="flex items-center justify-between my-4">
          <h1 className="text-xl font-bold">New Remix script</h1>
          <button
            onClick={() => setIsProMode(!isProMode)}
            className="text-gray-500 hover:text-gray-300 transition text-sm"
          >
            {isProMode ?
              'Basic' :
              <>
                {isProMode ? (
                  "Basic"
                ) : (
                  <span style={{ display: "inline-flex", alignItems: "center" }}>
                    Pro mode{" "}
                    <span
                      style={{
                        backgroundColor: "#ff0",
                        color: "#000",
                        fontWeight: "bold",
                        padding: "2px 6px",
                        borderRadius: "4px",
                        marginLeft: "8px",
                      }}
                    >
                      New
                    </span>
                  </span>
                )}
              </>
            }
          </button>
        </div>

        {isProMode ? (
          <ProRemixEditor
            scene={scene}
            lines={lines}
            setLines={setLines}
            username={username}
            navigate={navigate}
          />
        ) : (
          <BasicRemixEditor
            scene={scene}
            lines={lines}
            setLines={setLines}
            username={username}
            navigate={navigate}
          />
        )}

        <RelatedVideos
          originalSceneId={id}
          videoId={''}
          originalSceneTitle={scene.title}
          showMakeOne={false}
        />
      </div>
    </div>
  );
};

export default RemixEditor;
