import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInAnonymously } from 'firebase/auth';
import { auth } from '../Firebase.js';
import { useUser } from '../contexts/UserContext';
import { db } from '../Firebase.js';
import {
  collection,
  query,
  orderBy,
  getDocs,
  limit,
  where,
  startAfter,
  onSnapshot,
  doc
} from 'firebase/firestore';
import { useMediaQuery } from 'react-responsive';
import LazyVideo from './LazyVideo';
import InfiniteScroll from 'react-infinite-scroll-component';
import PublicVideos from './PublicVideos';
import SearchBar from './SearchBar';
import AppPromotion from './AppPromotion';


import { useParams } from 'react-router-dom';
import { getEndpoint } from '../utils';
import RelatedVideos from './RelatedVideos';

import { logEvent } from "../utils";


function SceneLibrary() {
  const [displayedScenes, setDisplayedScenes] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useUser();
  const username = currentUser?.email || currentUser?.uid;
  const ITEMS_PER_LOAD = 20;
  
  const searchTimeoutRef = useRef(null);
  const lastDocRef = useRef(null);
  const observerRef = useRef();

  const fetchScenes = async (searchValue = '') => {
    try {
      setLoading(true);
      let baseQuery = collection(db, 'remix-scenes-stock');
      
      if (searchValue.length >= 3) {
        baseQuery = query(
          baseQuery,
          where('search_terms', 'array-contains', searchValue.toLowerCase()),
          orderBy('timestamp', 'desc'),
          limit(ITEMS_PER_LOAD + 1)
        );
      } else {
        baseQuery = query(
          baseQuery,
          orderBy('timestamp', 'desc'),
          limit(ITEMS_PER_LOAD + 1)
        );
      }

      const querySnapshot = await getDocs(baseQuery);
      const allDocs = querySnapshot.docs;
      const scenesData = allDocs
        .slice(0, ITEMS_PER_LOAD)
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .filter(scene => !scene.hidden);

      setDisplayedScenes(scenesData);
      setHasMore(allDocs.length > ITEMS_PER_LOAD);
      lastDocRef.current = allDocs.length > ITEMS_PER_LOAD ? allDocs[ITEMS_PER_LOAD - 1] : null;
    } catch (error) {
      console.error('Error fetching scenes:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (value) => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      setSearchTerm(value);
      lastDocRef.current = null;
      setDisplayedScenes([]);
      setHasMore(true);
      fetchScenes(value);
    }, 300);
  };

  const fetchMoreData = async () => {
    if (!hasMore || !lastDocRef.current || loading) return;

    try {
      setLoading(true);
      let baseQuery = collection(db, 'remix-scenes-stock');
      
      if (searchTerm.length >= 3) {
        baseQuery = query(
          baseQuery,
          where('search_terms', 'array-contains', searchTerm.toLowerCase()),
          orderBy('timestamp', 'desc'),
          startAfter(lastDocRef.current),
          limit(ITEMS_PER_LOAD + 1)
        );
      } else {
        baseQuery = query(
          baseQuery,
          orderBy('timestamp', 'desc'),
          startAfter(lastDocRef.current),
          limit(ITEMS_PER_LOAD + 1)
        );
      }

      const querySnapshot = await getDocs(baseQuery);
      const allDocs = querySnapshot.docs;
      const newScenes = allDocs
        .slice(0, ITEMS_PER_LOAD)
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .filter(scene => !scene.hidden);

      setDisplayedScenes(prev => [...prev, ...newScenes]);
      setHasMore(allDocs.length > ITEMS_PER_LOAD);
      lastDocRef.current = allDocs.length > ITEMS_PER_LOAD ? allDocs[ITEMS_PER_LOAD - 1] : null;
    } catch (error) {
      console.error('Error fetching more scenes:', error);
    } finally {
      setLoading(false);
    }
  };

  const lastSceneRef = useCallback(
    (node) => {
      if (loading) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchMoreData();
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [loading, hasMore, searchTerm]
  );

  useEffect(() => {
    fetchScenes();
  }, []);

  const isNew = (timestamp) => {
    const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;
    const sceneDate = timestamp.toDate();
    const now = new Date();
    return now - sceneDate < threeDaysInMs;
  };

  return (
    <>
      <SearchBar onSearch={handleSearch} />
      <AppPromotion />
      <p className="text-xl font-bold text-gray-300 mb-4">Scene Library</p>
      
      {searchTerm.length > 0 && searchTerm.length < 3 && (
        <div className="flex flex-col items-center justify-center p-8 text-center">
          <p className="text-gray-400">
            Please enter at least 3 characters to search
          </p>
        </div>
      )}
      
      {!loading && displayedScenes.length === 0 && searchTerm.length >= 3 && (
        <div className="flex flex-col items-center justify-center p-8 text-center">
          <p className="text-gray-400">
            No scenes found matching "{searchTerm}"
          </p>
        </div>
      )}

      {(displayedScenes.length > 0 || loading) && (
        <div className="grid grid-cols-1 2xl:grid-cols-3 gap-6">
          {displayedScenes.map((scene, index) => (
            <div
              ref={index === displayedScenes.length - 1 ? lastSceneRef : null}
              key={scene.id}
            >
              <LazyVideo
                thumbnailUrl={scene.thumbnail_url}
                videoUrl={scene.video_url}
                title={scene.title}
                usageCount={scene.usage_count}
                isNew={isNew(scene.timestamp)}
                onClick={async () => {
                  navigate(`/remix/${scene.id}`);
                  await logEvent({
                    eventName: 'remix-click-homepage-thumbnail',
                    sceneId: scene.id,
                    title: scene.title,
                    user: username,
                  });
                }}
              />
            </div>
          ))}
        </div>
      )}

      {loading && (
        <div className="flex justify-center items-center p-4">
          <div className="w-10 h-10 rounded-full border-4 border-gray-700 border-t-green-400 animate-[spin_0.8s_ease-in-out_infinite]"></div>
        </div>
      )}

      {!hasMore && displayedScenes.length > 0 && !loading && (
        <p className="text-center text-gray-400 my-8">
          <b>No more scenes to display</b>
        </p>
      )}
    </>
  );
}

export default SceneLibrary;